import React from 'react'
import { styled } from '@mui/material/styles';
import {Badge, Tab} from "@mui/material";

export const StyledBadgeDefault = styled((props) =>
    <Badge {...props} />)(
    ({theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.badger,
            border: `1px solid ${theme.palette.badger}`,
            opacity: 'inherit',
            //padding: '0 12px',
        },
    }));

export const StyledBadgePrimary = styled((props) =>
    <Badge {...props} />)(
    ({theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: theme.palette.lesson.main,
        border: `1px solid ${theme.palette.lesson.main}`,
        opacity: 'inherit',
        //padding: '0 12px',
    },
}));

export const StyledBadgePrimaryDesktop = styled((props) =>
    <Badge {...props} />)(
    ({theme }) => ({
        '& .MuiBadge-dot': {
            height: 5,
            minWidth: 5,
        },
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            opacity: 'inherit',
            //padding: '0 12px',
        },
    }));

export const StyledBadgeSecondary = styled((props) =>
    <Badge {...props} />)(
    ({theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.secondary.main}`,
            opacity: 'inherit',
            //padding: '0 12px',
        },
    }));

export const StyledBadgeSecondaryDesktop = styled((props) =>
    <Badge {...props} />)(
    ({theme }) => ({
        '& .MuiBadge-dot': {
            height: 5,
            minWidth: 5,
        },
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.secondary.main}`,
            opacity: 'inherit',
            //padding: '0 12px',
        },
    }));

export const StyledBadgeWarning = styled((props) =>
    <Badge {...props} />)(
    ({theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.warning.main,
            border: `1px solid ${theme.palette.warning.main}`,
            opacity: 'inherit',
            //padding: '0 12px',
        },
    }));

export const StyledBadgeInfo = styled((props) =>
    <Badge {...props} />)(
    ({theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.info.main,
            border: `1px solid ${theme.palette.info.main}`,
            opacity: 'inherit',
            //padding: '0 12px',
        },
    }));

export const StyledBadgeWarningDesktop = styled((props) =>
    <Badge {...props} />)(
    ({theme }) => ({
        '& .MuiBadge-dot': {
            height: 5,
            minWidth: 5,
        },
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.warning.main,
            border: `1px solid ${theme.palette.warning.main}`,
            opacity: 'inherit',
            //padding: '0 12px',
        },
    }));

export const StyledBadgeEm = styled((props) =>
    <Badge {...props} />)(
    ({theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: 'inherit',
            border: `1px solid ${theme.palette.badger}`,
            opacity: 'inherit',
            //padding: '0 4px',
        },
    }));

export const StyledBadgeDesktop = styled((props) =>
    <Badge {...props} />)(
    ({theme }) => ({
        '& .MuiBadge-dot': {
            height: 5,
            minWidth: 5,
        },
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.badger,
            border: `1px solid ${theme.palette.badger}`,
            opacity: 'inherit',
            //padding: '0 4px',
        },
    }));