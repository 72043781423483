import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export default function HelloCard() {
    const rand = getRandomInt(5)

    const d = new Date();
    let hour = d.getHours();

    let message1 = ""
    let message2 = ""

    const messageMorning1 = "Доброе утро, Саша!"
    const messageMorning2 = "Как спалось?"

    const messageDay1 = "Добрый день, Саша!"
    const messageDay2 = "Как работа?"

    const messageEvening1 = "Добрый вечер, Саша!"
    const messageEvening2 = "Как прошёл день?"

    const messageNight1 = "Доброй ночи, Саша!"
    const messageNight2 = "Ну а тут и сказать нечего."

    console.log(hour)

    if (22 < hour || hour <= 5) {
        message1 = messageNight1
        message2 = messageNight2
    }

    if (5 < hour && hour <= 11) {
        message1 = messageMorning1
        message2 = messageMorning2
    }

    if (11 < hour && hour <= 16) {
        message1 = messageDay1
        message2 = messageDay2
    }

    if (16 < hour && hour <= 22) {
        message1 = messageEvening1
        message2 = messageEvening2
    }

    return (
        <Card elevation={0}>
            <CardMedia
                component="img"
                height="300"
                image={`/static/rrr${rand}.jpg`}
                alt={rand}
            />
            <CardContent>

                <Typography gutterBottom variant="h5" component="div">
                    {message1}
                </Typography>
                <Typography variant="h6" color="text.secondary">
                    {message2}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Добро пожаловать, Саша (девочка)
                </Typography>
            </CardContent>
        </Card>
    );
}