import React from 'react'
import { styled } from '@mui/material/styles';
import {Badge, Box, Tab, Tabs, Typography} from "@mui/material";
import {StyledBadge} from "./Badge";
import PropTypes from "prop-types";

export const StyledTabs = styled((props) =>
    <Tabs
        //variant={"fullWidth"}
        {...props}
        TabIndicatorProps={!props.hide ? {...props.TabIndicatorProps, children: <span className="MuiTabs-indicatorSpan" /> } : {...props.TabIndicatorProps}}
    />
)(({ theme }) => ({
    '& .MuiTabs-indicatorSpan': {
        width: '90%',
        backgroundColor: theme.palette.ui.tab,
        borderRadius: 50
    },
}));

export const StyledXTabs = styled((props) =>
    <Tabs
        variant={"fullWidth"}
        {...props}
        TabIndicatorProps={!props.hide ? {...props.TabIndicatorProps, children: <span className="MuiTabs-indicatorSpan" /> } : {...props.TabIndicatorProps}}
    />
)(({ theme }) => ({
    '& .MuiTabs-indicatorSpan': {
        width: '90%',
        backgroundColor: theme.palette.ui.tab,
        borderRadius: 50
    },
    '& .MuiTabs-fixed': {
        marginLeft: '5vw !important',
        marginRight: '5vw !important'
    },
}));

export const StyledTab2Sel = styled((props) =>
    <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        transition: 'all 0.2s ease-in-out',
        opacity: 1,
        '&.Mui-selected': {
            fontSize: theme.typography.pxToRem(20),
        },
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(20),
    }),
);

export const StyledTab2 = styled((props) =>
    <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        transition: 'all 0.2s ease-in-out',
        '&.Mui-selected': {
            fontSize: theme.typography.pxToRem(20),
        },
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(20),
    }),
);

export const StyledTab = styled((props) =>
    <Tab sx={{
        paddingX: '10px',
        paddingY: '12px',
        minWidth: 0,
    }} disableRipple {...props} />)(
    ({ theme }) => ({
        borderRadius: '15px',
        transition: 'all 0.2s ease-in',
        '&.Mui-selected': {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightBold,
        },
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
    }),
);


export const StyledMapTabs = styled((props) =>
    <Tabs variant={'scrollable'} sx={{backgroundColor:'transparent'}}
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        top:1,
        height: 0,
    },
    '& .MuiTabs-indicatorSpan': {
        width: '90%',
        opacity: 0,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50
    },
}));

export const StyledMapTab = styled((props) =>
    <Tab sx={() => {if (props.count) {return {marginRight: 2}} else {return {borderRadius: '50px', marginX: 0.5, paddingX: 3}}}}   disableRipple {...props} />)(
    ({ theme }) => ({
        opacity: 1,
        backdropFilter: 'blur(20px) !important',
        backgroundColor: theme.palette.nbColor,
        border: `1px solid ${theme.palette.divider}`,
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(15),
        marginY: 5,
        //border: `3px solid ${theme.palette.primary.main}`,
        //borderRight: `1px solid ${theme.palette.divider}`
    }),
);

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{
                minHeight: '65vh',
                backgroundColor: 'transparent'
            }}
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{width: '100%'}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export function TabPanelAlt(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{
                backgroundColor: 'transparent'
            }}
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{width: '100%'}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};