import {styled} from "@mui/material/styles";
import {Paper, Stack} from "@mui/material";
import React from "react";
import {PickersDay} from "@mui/x-date-pickers";
import {
    StyledBadgeDefault,
    StyledBadgeDesktop,
    StyledBadgeEm,
    StyledBadgePrimary, StyledBadgePrimaryDesktop,
    StyledBadgeSecondary, StyledBadgeSecondaryDesktop,
    StyledBadgeWarning, StyledBadgeWarningDesktop
} from "./Badge";

export function Dots(value) {
    switch (value) {
        case 'Лекция':
            return <StyledBadgePrimaryDesktop variant="dot" color="primary"/>
        case 'Практическое занятие':
            return <StyledBadgeSecondaryDesktop variant="dot" color="primary"/>
        case 'Консультация':
            return <StyledBadgeWarningDesktop variant="dot" color="primary"/>
        default:
            return <StyledBadgeDesktop variant="dot" color="primary"/>
    }
}

export const XPickerDay = styled((props) =>
    <Paper elevation={0} sx={{position: 'relative'}}>
        <PickersDay {...props} />
        {props.cont !== undefined && !props.outsideCurrentMonth && !props.selected ? <Stack direction={'row'} sx={{transform: 'translate(-50%, 0%)', position: 'absolute', bottom: 6, left: '50%'}} spacing={1}>
            {
                props.cont.map((value) => Dots(value))
            }
        </Stack> : null}
    </Paper>)(
    ({theme}) => ({
        borderRadius: '10px',
    }),
);