import JSEncrypt from "jsencrypt";


function Encrypt(password) {
    // Start our encryptor.
    var encrypt = new JSEncrypt();

    // Copied from https://github.com/travist/jsencrypt
    var publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCeVmONMNiBDwoEJ1uTfGB6LBsI
QrtzQaoY9n0W0aycdlYVctrQ5KMRiylyFMppCvPA4FGXEkB7jc0AXAKdKrJi7FzF
nNBQVnviyNWQa0GjgpI3EwLqY6vNSN9ETad29o1yBFdO2c5xNivILSna0r2Ktvge
j5q81su3xOBrVtyupwIDAQAB
-----END PUBLIC KEY-----`;

    // Assign our encryptor to utilize the public key.
    encrypt.setPublicKey(publicKey);

    // Perform our encryption based on our public key - only private key can read it!
    var encrypted = encrypt.encrypt(password);
    return encrypted;
}

export default Encrypt;