import React, {useState} from 'react';
import {
    BsBarChartLineFill, BsBook, BsBookFill,
    BsBookHalf, BsBuildingFill, BsBuildings, BsBuildingsFill, BsCalendar2Check, BsCalendar2CheckFill,
    BsCalendar2WeekFill, BsGearWide,
    BsGearWideConnected, BsGrid, BsGridFill, BsHouse, BsHouseFill, BsPatchCheck, BsPatchCheckFill, BsPinMap,
    BsPinMapFill,
    BsUiChecksGrid, BsUiRadios
} from "react-icons/bs";
import './App.css';
import {
    BottomNavigation,
    BottomNavigationAction,
    Button,
    Paper,
    Tab,
    Tabs,
    ThemeProvider,
    useMediaQuery
} from "@mui/material";
import {FactCheck, FmdGood, MenuBook, Settings, ViewAgenda} from "@mui/icons-material"
import {SettingsPage} from "./Pages/Settings";
import {LessonsPage} from "./Pages/Lessons";
import {TodayPage} from "./Pages/Today";
import {MapsPage} from "./Pages/Map";
import {DarkTheme, ExtTheme, PaletteContext, themes} from "./theme";
import {Helmet} from "react-helmet";
import {FullScreenDialog, ReynoldsDialog} from "./Components/Dialog";
import {BarsPage} from "./Pages/Bars";
import {isBrowser} from "react-device-detect"
import Snowfall from 'react-snowfall';
import {TodaySmartPage} from "./Pages/TodaySmart";
import {LessonsXPage} from "./Pages/LessonsX";
import {FaCogs, FaMapMarkerAlt} from "react-icons/fa";
import {
    BiMap
} from "react-icons/bi";

const pages = [
    <TodayPage/>,
    <BarsPage/>,
    <LessonsPage/>,
    <MapsPage/>,
    <SettingsPage/>
]

const Xpages = [
    <TodayPage/>,
    <BarsPage/>,
    <LessonsXPage/>,
    <MapsPage/>,
    <SettingsPage/>
]

function App() {
    const [palette, usePalette] = useState(localStorage.getItem('theme2') ? JSON.parse(localStorage.getItem('theme2')) : {color: 'NIGHT'})

    let isDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

    function GetTheme() {
        if (isDarkMode && palette.design === 'auto') {
            return DarkTheme(themes['NIGHT'])
        }

        if (!isDarkMode && palette.design === 'auto') {
            return ExtTheme(themes['NIGHT'])
        }

        if (palette.design === 'extended') {
            return ExtTheme(themes['NIGHT'])
        }

        if (palette.design === 'dark') {
            return DarkTheme(themes['NIGHT'])
        }


        return ExtTheme(getTheme())
        //return BaseTheme(getTheme())
    }

    function getTheme() {
        switch (palette.color) {
            case 'DEFAULT':
                return themes['DEFAULT']
            case 'MATERIAL':
                return themes['MATERIAL']
            case 'CASUAL':
                return themes['CASUAL']
            case 'PURPLE':
                return themes['PURPLE']
            case 'NIGHT':
                return themes['NIGHT']
            case 'APELSIN':
                return themes['APELSIN']
            default:
                return themes['DEFAULT']
        }
    }

    function ChooseTheme(value) {
        usePalette(value)
    }

    return (
        <div className="App">
            {localStorage.getItem("snow") === "true" ?
                <div
                    style={{height: '100%', width: '100%', zIndex: 0, background: 'transparent', position: 'absolute'}}>
                    <Snowfall/>
                </div> : null
            }
            <PaletteContext.Provider value={{palette: GetTheme, usePalette: ChooseTheme}}>
                <AppBody/>
            </PaletteContext.Provider>
        </div>
    );
}

function AppBody() {
    const [value, setValue] = useState(2);
    if (localStorage.getItem('rrr') !== 'false' && localStorage.getItem('rrr') !== 'true') {
        localStorage.setItem('rrr', 'false')
    }

    if (localStorage.getItem('snow') !== 'false' && localStorage.getItem('snow') !== 'true') {
        localStorage.setItem('snow', 'false')
    }

    return (
        <React.Fragment>
            <PaletteContext.Consumer>
                {context =>
                    <ThemeProvider theme={context.palette()}>
                        <Helmet>
                            <meta name="theme-color" content={context.palette().palette.ui.main}/>
                            <style>{`body { background-color: ${context.palette().palette.background.default}; }`}</style>
                            {context.palette().palette.mode === 'dark' ?
                                <style>{`.ymaps-2-1-79-inner-panes {filter: invert(100%);}`}</style> : null}
                        </Helmet>
                        {localStorage.getItem('rrr') === 'true'
                            ? <ReynoldsDialog/>
                            : null
                        }
                        {
                            isBrowser ? <Paper
                                sx={{zIndex: 10}}
                                elevation={value === 3 ? 0 : 0}>
                                <Tabs value={value} onChange={(event, newValue) => setValue(newValue)}>
                                    <Tab iconPosition={"start"} icon={<FactCheck/>} value={0} label="Сводка"/>
                                    <Tab iconPosition={"start"} icon={<MenuBook/>} value={1} label="БАРС"/>
                                    <Tab iconPosition={"start"} icon={<ViewAgenda/>} value={2} label="Расписание"/>
                                    <Tab iconPosition={"start"} icon={<FmdGood/>} value={3} label="Карта"/>
                                    <Tab iconPosition={"start"} icon={<Settings/>} value={4} label="Настройки"/>
                                </Tabs>
                            </Paper> : null
                        }
                        <Paper elevation={0} sx={{pb: isBrowser ? 0 : 10}}>
                            {localStorage.getItem('uiVersion') === 'true' ? pages[value] : Xpages[value]}
                        </Paper>
                        {
                            value === -1 && localStorage.getItem('groupId') !== null ? <React.Fragment>
                                <FullScreenDialog/>
                            </React.Fragment> : null
                        }
                        {
                            isBrowser ? null : NavBar(localStorage.getItem('desPlat'), value, setValue, context)
                        }
                    </ThemeProvider>
                }
            </PaletteContext.Consumer>
        </React.Fragment>
    )
}

function NavBar(type, value, setValue, context) {
    switch (type) {
        case 'Android':
            return <Paper
                sx={{
                    pb: 'env(safe-area-inset-bottom, 10px)',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    borderRadius: 0,
                    //borderTop: `1px solid ${context.palette().palette.divider}`
                }}
                elevation={value === 3 ? 0 : 0}>
                <BottomNavigation
                    sx={{backgroundColor: 'transparent'}}
                    showLabels={false}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction sx={{borderRadius: '50px'}} label=""
                                            icon={<FactCheck sx={{fontSize: 28}}/>}/>
                    <BottomNavigationAction sx={{borderRadius: '50px'}} label=""
                                            icon={<MenuBook sx={{fontSize: 28}}/>}/>
                    <BottomNavigationAction sx={{borderRadius: '50px'}} label=""
                                            icon={<ViewAgenda sx={{fontSize: 28}}/>}/>
                    <BottomNavigationAction sx={{borderRadius: '50px'}} label=""
                                            icon={<FmdGood sx={{fontSize: 28}}/>}/>
                    <BottomNavigationAction sx={{borderRadius: '50px'}} label=""
                                            icon={<Settings sx={{fontSize: 28}}/>}/>
                </BottomNavigation>
            </Paper>
        case 'IOS':
        default:
            return <Paper
                sx={{
                    //mx: 0.5,
                    pb: 'env(safe-area-inset-bottom, 10px)',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    borderRadius: 0,
                    backdropFilter: 'blur(20px)',
                    backgroundColor: context.palette().palette.nbColor,
                    borderTop: `1px solid ${context.palette().palette.divider}`
                }}
                elevation={value === 3 ? 0 : 0}>
                <BottomNavigation
                    sx={{backgroundColor: 'transparent'}}
                    showLabels={false}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction disableRipple disableTouchRipple sx={{borderRadius: '50px'}} label=""
                                            icon={value === 0 ? <BsCalendar2CheckFill style={{fontSize: 26}}/> : <BsCalendar2Check style={{fontSize: 26}}/> }/>
                    <BottomNavigationAction disableRipple disableTouchRipple sx={{borderRadius: '50px'}} label=""
                                            icon={value === 1 ?  <BsBookHalf style={{fontSize: 26}}/> : <BsBook style={{fontSize: 26}}/>}/>
                    <BottomNavigationAction disableRipple disableTouchRipple sx={{borderRadius: '50px'}} label=""
                                            icon={value === 2 ?  <BsGridFill style={{fontSize: 26}}/> : <BsGrid style={{fontSize: 26}}/>}/>
                    <BottomNavigationAction disableRipple disableTouchRipple sx={{borderRadius: '50px'}} label=""
                                            icon={value === 3 ?  <BsPinMapFill style={{fontSize: 26}}/> : <BsPinMap style={{fontSize: 26}}/>}/>
                    <BottomNavigationAction disableRipple disableTouchRipple sx={{borderRadius: '50px'}} label=""
                                            icon={value === 4 ?  <BsGearWideConnected style={{fontSize: 26}}/> : <BsGearWide style={{fontSize: 26}}/>}/>
                </BottomNavigation>
            </Paper>
    }
}

export default App;
