import React, {useEffect, useState} from 'react'
import {Box, Stack, TextField} from "@mui/material";
import ReactJson from 'react-json-view'
import {useTheme} from "@mui/material/styles";


export const ApiPage = () => {
    const theme = useTheme()
    const [data, setData] = useState({})
    const [term, setTerm] = useState('ИЭозс-62-20')

    useEffect(() => {
        fetch('/api/search?term='+term)
            .then((res) => res.json())
            .then((res) => setData(res))

    }, [])

    useEffect(() => {
        fetch('/api/search?term='+term)
            .then((res) => res.json())
            .then((res) => setData(res))

    }, [term])


    return <Box sx={{marginY: 1, marginX: 2}}>
        <Stack spacing={2}>
            <TextField value={term} onChange={(event) => {
                setTerm(event.target.value)
            }}/>
            <ReactJson
                displayDataTypes={false}
                displayObjectSize={false}
                theme={theme.palette.code}
                style={{fontSize: 14}}
                src={data} />
        </Stack>
    </Box>
}