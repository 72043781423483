import React, {useEffect, useState} from "react";
import {
    AppBar,
    Badge,
    Box,
    Button,
    CardActionArea,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    Stack,
    Typography,
    useScrollTrigger
} from "@mui/material";
import {StyledTab, StyledTabs, StyledXTabs, TabPanel} from "../Components/Tab";
import {useTheme} from '@mui/material/styles';
import SwipeableViews from "react-swipeable-views";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Bull} from "../Components/Bull";
import {
    StyledBadgeDefault,
    StyledBadgeEm, StyledBadgeInfo,
    StyledBadgePrimary,
    StyledBadgeSecondary,
    StyledBadgeWarning
} from "../Components/Badge";
import {FailPage} from "./Fail";
import {ArrowBack, ArrowForward, Cake, DoneAll} from "@mui/icons-material";
import {buildUrl} from "../ics";
import {bindKeyboard} from 'react-swipeable-views-utils';
import {BrowserView, isBrowser, MobileView} from "react-device-detect"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {StaticDatePicker} from "@mui/x-date-pickers/StaticDatePicker";
import {XPickerDay} from "../Components/Day";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import {BsArrowLeft, BsArrowRight, BsCheck2All, BsJournalCheck} from "react-icons/bs";

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

function checkColor(value, theme) {
    switch (value) {
        case 'Лекция':
            return 'lesson'
        case 'Практическое занятие':
            return 'secondary'
        case 'Консультация':
            return 'warning'
        case '1Зачет с оценкой (по совокупности результатов текущего контроля)':
            return 'info'
        case '1Экзамен':
            return 'info'
    }
}

function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1);
    return new Date(d.setDate(diff));
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('.');
}

function formatDateWithSep(date) {
    return date.replaceAll('.', '-')
}

function formatTabDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month].join('.');
}

function nextDay(date) {
    let nextD = new Date(date)
    nextD.setDate(nextD.getDate() + 1)

    return nextD
}

function makeItems(days, items) {
    let itemList = []

    days.map(day => {
        itemList.push(items.filter(value => {
            return formatDate(day) === value.date;
        }))
    })

    console.log(itemList)

    return itemList
}

export function Dots(value) {
    switch (value) {
        case 'Лекция':
            return <StyledBadgePrimary variant="dot" color="primary"/>
        case 'Практическое занятие':
            return <StyledBadgeSecondary variant="dot" color="primary"/>
        case 'Консультация':
            return <StyledBadgeWarning variant="dot" color="primary"/>
        case '1Зачет с оценкой (по совокупности результатов текущего контроля)':
            return <StyledBadgeInfo variant="dot" color="primary"/>
        case '1Экзамен':
            return <StyledBadgeInfo variant="dot" color="primary"/>
        default:
            return <StyledBadgeDefault variant="dot" color="primary"/>
    }
}

export function LessonsXPage(props) {
    const {window: _window} = props;

    const [expanded, setExpanded] = useState(false)
    const [currentDay, setCurrentDay] = useState('СЕГОДНЯ')

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: -100,
        target: props.window ? _window() : undefined,
    });

    const trigger2 = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
        target: props.window ? _window() : undefined,
    });

    const theme = useTheme();

    if (!trigger) {
        if (!expanded) {
            setExpanded(true)
        }
    }

    if (trigger2) {
        if (expanded) {
            setExpanded(false)
        }
    }

    let today = new Date()
    const tIndex = today.getDay() === 0 ? 0 : today.getDay() - 1

    const [tab, setTab] = useState(today.getDay() === 0 ? 0 : today.getDay() - 1);
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const [items, setItems] = useState([]);
    const [days, setDays] = useState([])
    const [value, setValue] = React.useState(dayjs());
    const [day, setDay] = React.useState([]);
    const [_month, _setMonth] = React.useState({});


    let mn = getMonday(new Date())
    let curDate = new Date()
    if (curDate.getDay() === 0) {
        let newMn = new Date(mn)
        mn.setDate(newMn.getDate() + 7)
    }

    const [monday, setMonday] = useState(mn)
    const [loading, setLoading] = useState(true)
    const [failed, setFailed] = useState(false)

    const isWeekend = (date) => {
        const day = date.day();

        return day === 0;
    };

    useEffect(() => {
        if (isBrowser) {
            return
        }
        let dayFromMonday = new Date(monday)
        let tuesday = nextDay(dayFromMonday)
        let wednesday = nextDay(tuesday)
        let thursday = nextDay(wednesday)
        let friday = nextDay(thursday)
        let saturday = nextDay(friday)

        let dDays = [dayFromMonday, tuesday, wednesday, thursday, friday, saturday]

        let dateStart = formatDate(monday)
        let dateEnd = formatDate(saturday)

        let days = [
            `${formatTabDate(dayFromMonday)}`,
            `${formatTabDate(tuesday)}`,
            `${formatTabDate(wednesday)}`,
            `${formatTabDate(thursday)}`,
            `${formatTabDate(friday)}`,
            `${formatTabDate(saturday)}`,
        ]

        /*if (localStorage.getItem('groupId') === null) {
            alert('Настройте группу')
            return
        }*/

        fetch(`api/schedule/group/${localStorage.getItem('groupId')}?start=${dateStart}&finish=${dateEnd}&lng=1`, {})
            .then(res => res.json())
            .then(
                (result) => {
                    setLoading(false)
                    setDays(days)
                    setItems(makeItems(dDays, result))
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    console.log(error)
                    setFailed(true)
                }
            )

        setExpanded(false)
    }, [])

    function setDDD(result) {
        let res = {}

        if (result.length === 0) {
            return {}
        }

        result.map((value) => {
            if (res[value.date] === undefined) {
                res[value.date] = [value.kindOfWork]
                return
            }
            res[value.date].push(value.kindOfWork)
        })

        return res
    }

    useEffect(() => {
        if (!isBrowser) {
            return
        }

        let today = new Date(value)
        let dateStart = formatDate(today)

        fetch(`api/schedule/group/${localStorage.getItem('groupId')}?start=2023.02.01&finish=2023.07.23&lng=1`, {})
            .then(res => res.json())
            .then(
                (result) => {
                    setLoading(false)
                    _setMonth(setDDD(result))
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    console.log(error)
                }
            )


        fetch(`api/schedule/group/${localStorage.getItem('groupId')}?start=${dateStart}&finish=${dateStart}&lng=1`, {})
            .then(res => res.json())
            .then(
                (result) => {
                    setLoading(false)
                    setDay(result)
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    console.log(error)
                    setFailed(true)
                }
            )

        setExpanded(false)
    }, [])

    useEffect(() => {
        let today = new Date(value)
        let dateStart = formatDate(today)


        fetch(`api/schedule/group/${localStorage.getItem('groupId')}?start=${dateStart}&finish=${dateStart}&lng=1`, {})
            .then(res => res.json())
            .then(
                (result) => {
                    setLoading(false)
                    setDay(result)
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    console.log(error)
                    setFailed(true)
                }
            )

        setExpanded(false)
    }, [value])

    let ddd = [
        `ПН`,
        `ВТ`,
        `СР`,
        `ЧТ`,
        `ПТ`,
        `СБ`,
    ]

    useEffect(() => {
        let dayFromMonday = new Date(monday)
        let tuesday = nextDay(dayFromMonday)
        let wednesday = nextDay(tuesday)
        let thursday = nextDay(wednesday)
        let friday = nextDay(thursday)
        let saturday = nextDay(friday)

        let dDays = [dayFromMonday, tuesday, wednesday, thursday, friday, saturday]

        let dateStart = formatDate(monday)
        let dateEnd = formatDate(saturday)

        let days = [
            `${formatTabDate(dayFromMonday)}`,
            `${formatTabDate(tuesday)}`,
            `${formatTabDate(wednesday)}`,
            `${formatTabDate(thursday)}`,
            `${formatTabDate(friday)}`,
            `${formatTabDate(saturday)}`,
        ]

        fetch(`api/schedule/group/${localStorage.getItem('groupId')}?start=${dateStart}&finish=${dateEnd}&lng=1`, {})
            .then(res => res.json())
            .then(
                (result) => {
                    setLoading(false)
                    setDays(days)
                    setItems(makeItems(dDays, result))
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    console.log(error)
                    setFailed(true)
                }
            )
    }, [monday])

    const handleExpand = (panel) => (event, newExpanded) => {
        console.log(1)
        //setExpanded(!expanded);
    };

    if (failed) {
        return (
            <FailPage type="fail"/>
        )
    }

    const card = localStorage.getItem('desCard') ? localStorage.getItem('desCard') : 'IOS'
    const tabVar = localStorage.getItem('desTab') ? localStorage.getItem('desTab') : 'column'

    return (
        localStorage.getItem('groupId') !== null ?
            <React.Fragment>
                <BrowserView>
                    <Box sx={{flexGrow: 1, backgroundColor: 'transparent', minHeight: '100%'}}>
                        <AppBar position="sticky">
                            {loading
                                ? <div style={{
                                    display: 'flex', justifyContent: 'center',
                                    marginTop: '3vh',
                                    marginBottom: '3vh',
                                }}><CircularProgress sx={{color: 'white'}}/></div>
                                : null
                            }
                        </AppBar>
                        <Grid container>
                            <Grid item xs={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
                                    <StaticDatePicker
                                        views={["day"]}
                                        label={"Выбор дня"}
                                        //orientation="landscape"
                                        openTo="day"
                                        value={value}
                                        shouldDisableDate={isWeekend}
                                        componentsProps={{
                                            actionBar: {
                                                actions: null,
                                            },
                                        }}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                        renderDay={(day, selectedDays, pickersDayProps) =>
                                            <XPickerDay cont={_month[formatDate(day)]} {...pickersDayProps}/>}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={8}>
                                {day.length === 0 ? <React.Fragment>
                                        <div style={{
                                            display: 'flex', justifyContent: 'center',
                                            paddingTop: '20vh'
                                        }}>
                                            <DoneAll sx={{fontSize: '15vh', color: theme.palette.primary.main}}/>
                                        </div>
                                        <div style={{
                                            display: 'flex', justifyContent: 'center',
                                            paddingTop: '5vh'
                                        }}>
                                            <Typography variant={'h6'}><b>ПАРЫ ОТСУТСТВУЮТ</b></Typography>
                                        </div>
                                    </React.Fragment>
                                    : day.map(item => (
                                        <React.Fragment>
                                            <Card variant={"elevation"} elevation={0} sx={{minWidth: 275}}>
                                                <Grid container alignItems="center">
                                                    <Grid sx={{textAlign: 'center'}} item xs={1}>
                                                        <h2>{item.contentTableOfLessonsName}</h2>
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <CardActionArea onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();

                                                            let _event = {
                                                                title: item.discipline,
                                                                description: item.kindOfWork,
                                                                startTime: `${formatDateWithSep(item.date)}T${item.beginLesson}:00+03:00`,
                                                                endTime: `${formatDateWithSep(item.date)}T${item.endLesson}:00+03:00`,
                                                                location: `${item.building} ${item.auditorium}`
                                                            }

                                                            let url = buildUrl(_event)

                                                            let proxy = window.open(url, '_blank')
                                                            setTimeout(() => {
                                                                proxy.close()
                                                            }, 500)
                                                        }}>
                                                            <CardContent>
                                                                <Stack direction="row"
                                                                       spacing={1}>
                                                                    <Chip sx={{mb: 1.5}} label={item.kindOfWork}
                                                                          color={checkColor(item.kindOfWork)}/>
                                                                    {item.stream ?
                                                                        <Chip sx={{mb: 1.5}} label={item.stream}
                                                                        /> : null}
                                                                </Stack>

                                                                <Typography sx={{mb: 1.2}} variant="h5" component="div">
                                                                    {item.discipline}
                                                                </Typography>

                                                                <Typography sx={{mb: 1.5}} color="text.secondary">
                                                                    {item.lecturer}
                                                                </Typography>
                                                                <Typography sx={{mb: 1.5}} variant="body2">
                                                                    {item.building} {Bull} {item.auditorium} {Bull} {item.auditoriumAmount} мест(а) </Typography>
                                                                <Stack
                                                                    direction="row"
                                                                    divider={<Divider orientation="vertical" flexItem/>}
                                                                    spacing={2}
                                                                >
                                                                    <Typography
                                                                        variant="h6">{item.beginLesson}</Typography>
                                                                    <Typography
                                                                        variant="h6">{item.endLesson}</Typography>
                                                                </Stack>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            <Divider/>
                                        </React.Fragment>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </BrowserView>
                <MobileView>
                    <Box sx={{flexGrow: 1, backgroundColor: 'transparent', minHeight: '100%'}}>
                        <AppBar position="sticky">
                            {loading
                                ? <div style={{
                                    display: 'flex', justifyContent: 'center',
                                    marginTop: '3vh',
                                    marginBottom: '3vh',
                                }}><CircularProgress sx={{color: 'white'}}/></div>
                                : <React.Fragment>
                                    <Grid container sx={{py: 2, px: '5vw'}}>
                                        <Grid item xs={6}>
                                            <div style={{
                                                display: 'flex', alignItems: 'center', height: '100%'
                                            }}>
                                                <Typography fontWeight={"bold"}
                                                            variant={'h5'}>{sep(days[tab])}</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100%'
                                            }}>
                                                <Button color={'primary'} disableRipple disableTouchRipple disableFocusRipple
                                                        onClick={() => {
                                                            let newMn = new Date(monday)
                                                            newMn.setDate(newMn.getDate() - 7)
                                                            setMonday(newMn)
                                                            setTab(0)
                                                        }} disableElevation variant={'outlined'}><BsArrowLeft style={{fontSize: 22}}/></Button>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100%'
                                            }}>
                                                <Button color={'primary'} disableRipple disableTouchRipple disableFocusRipple
                                                        onClick={() => {
                                                            let newMn = new Date(monday)
                                                            newMn.setDate(newMn.getDate() + 7)
                                                            setMonday(newMn)
                                                            setTab(0)
                                                        }} disableElevation
                                                        variant={'outlined'}><BsArrowRight style={{fontSize: 22}}/></Button>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    {/* <StyledTabs
                                hide
                                value={tab}
                                onChange={handleChange}
                                indicatorColor="primary"
                                variant={{'scrollable'}
                                //textColor="inherit"
                                aria-label="full width tabs example"
                            >
                                {ddd.map((value, index) => (
                                    <StyledTab2
                                        label={<Stack direction={"row"} spacing={2}>
                                            <div>{value.toUpperCase()}</div>
                                        </Stack>}/>
                                ))
                                }

                            </StyledTabs>*/}

                                    {tabVar === 'column'
                                        ? <StyledXTabs
                                            hide
                                            centered
                                            value={tab}
                                            onChange={handleChange}
                                            indicatorColor="primary"
                                            aria-label="full width tabs example"
                                        >
                                            {days.map((value, index) => (
                                                <StyledTab
                                                    label={<Stack sx={{flexGrow: 1}} direction={"column"}
                                                                  spacing={1}>
                                                        <div>{ddd[index]}</div>
                                                        {items[index].length !== 0 ?
                                                            <div>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexGrow: 1,
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-around',
                                                                    height: '18px'
                                                                }}>
                                                                    {items[index].map((value, index) =>
                                                                        index <= 2 ? Dots(value.kindOfWork) : null)}
                                                                </div>
                                                            </div> : tab === index ?
                                                                value === '30.06' && isPaskh() ? <div>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            flexGrow: 1,
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-around',
                                                                            height: '18px'
                                                                        }}>❤️
                                                                        </div>
                                                                    </div>
                                                                    : <div>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            flexGrow: 1,
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-around',
                                                                            height: '18px'
                                                                        }}>
                                                                            <StyledBadgeEm variant="dot" color="primary"/>
                                                                        </div>
                                                                    </div> : null}
                                                    </Stack>}/>
                                            ))
                                            }

                                        </StyledXTabs>
                                        : <StyledTabs
                                            sx={{paddingX: 2}}
                                            hide
                                            variant={'scrollable'}
                                            //centered
                                            value={tab}
                                            onChange={handleChange}
                                            indicatorColor="primary"
                                            aria-label="full width tabs example"
                                        >
                                            {days.map((value, index) => (
                                                <StyledTab sx={tab === index ? {
                                                    backgroundColor: theme.palette.tabShadow,
                                                    marginX: 0.0,
                                                } : {}}
                                                           label={
                                                               <Stack direction={"row"} spacing={1}>
                                                                   <div>{ddd[index]}</div>
                                                                   <div>({value})</div>
                                                                   {items[index].length !== 0 ?
                                                                       <Badge
                                                                           color={checkColor(items[index][0].kindOfWork)}
                                                                           anchorOrigin={{
                                                                               vertical: 'top',
                                                                               horizontal: 'right',
                                                                           }}
                                                                           variant={"dot"}
                                                                           sx={card === 'IOS' ? {
                                                                               fontWeight: 'bold',
                                                                               backgroundColor: theme.palette.cardColor
                                                                           } : {}}
                                                                           badgeContent={items[index].length}/>
                                                                       : null}
                                                               </Stack>}/>
                                            ))
                                            }

                                        </StyledTabs>}

                                </React.Fragment>
                            }
                        </AppBar>
                        <BindKeyboardSwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={tab}
                            style={{backgroundColor: 'transparent'}}
                            onChangeIndex={(index) => {
                                if (tab === 0 && index === 5) {
                                    let newMn = new Date(monday)
                                    newMn.setDate(newMn.getDate() - 7)
                                    setMonday(newMn)
                                    setTab(index)
                                    return
                                }
                                if (tab === 5 && index === 0) {
                                    let newMn = new Date(monday)
                                    newMn.setDate(newMn.getDate() + 7)
                                    setMonday(newMn)
                                    setTab(index)
                                    return
                                }
                                setTab(index)
                            }}
                        >
                            {items.map((value, index) => (
                                <TabPanel value={tab} index={index} dir={theme.direction}>
                                    {value.length === 0 ?
                                        <React.Fragment>
                                            <div style={{
                                                display: 'flex', justifyContent: 'center',
                                                paddingTop: '20vh'
                                            }}>
                                                {
                                                    days[tab] === '30.06' && isPaskh() ?
                                                        <Cake
                                                            sx={{fontSize: '15vh', color: theme.palette.lesson.main}}/>
                                                        : <DoneAll
                                                            style={{fontSize: '15vh', color: theme.palette.lesson.main}}/>
                                                }
                                            </div>
                                            <div style={{
                                                display: 'flex', justifyContent: 'center',
                                                paddingTop: '5vh'
                                            }}>
                                                <Typography variant={'h6'}><b>ПАРЫ ОТСУТСТВУЮТ</b></Typography>
                                            </div>
                                        </React.Fragment>
                                        : value.map(item => (
                                            <React.Fragment>
                                                <Card variant={"elevation"} elevation={0} sx={{minWidth: 275}}>
                                                    <Grid container alignItems="center">
                                                        <Grid sx={{textAlign: 'center'}} item xs={1}>
                                                            <h2>{item.contentTableOfLessonsName}</h2>
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <CardActionArea onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();

                                                                let _event = {
                                                                    title: item.discipline,
                                                                    description: item.kindOfWork,
                                                                    startTime: `${formatDateWithSep(item.date)}T${item.beginLesson}:00+03:00`,
                                                                    endTime: `${formatDateWithSep(item.date)}T${item.endLesson}:00+03:00`,
                                                                    location: `${item.building} ${item.auditorium}`
                                                                }

                                                                let url = buildUrl(_event)

                                                                let proxy = window.open(url, '_self')
                                                                setTimeout(() => {
                                                                    //proxy.close()
                                                                }, 500)
                                                            }}>
                                                                <CardContent>
                                                                    <Stack direction="row"
                                                                           spacing={1}>
                                                                        <Chip
                                                                            variant={card === 'IOS' ? 'outlined' : 'filled'}
                                                                            sx={
                                                                                card === 'IOS' ?
                                                                                    {
                                                                                        mb: 1.5,
                                                                                        border: '10px',
                                                                                        fontWeight: 'bold',
                                                                                        backgroundColor: theme.palette.cardColor
                                                                                    }
                                                                                    : {mb: 1.5, border: '10px'}
                                                                            }
                                                                            label={card === 'IOS' ? item.kindOfWork.toUpperCase() : item.kindOfWork}
                                                                            color={checkColor(item.kindOfWork)}/>
                                                                        {item.stream ?
                                                                            <Chip
                                                                                variant={card === 'IOS' ? 'outlined' : 'filled'}
                                                                                sx={card === 'IOS' ? {
                                                                                    mb: 1.5,
                                                                                    fontWeight: 'bold',
                                                                                    border: 0,
                                                                                    backgroundColor: theme.palette.cardColor
                                                                                } : {mb: 1.5}}
                                                                                label={card === 'IOS' ? item.stream.toUpperCase() : item.stream}
                                                                            /> : null}
                                                                    </Stack>

                                                                    <Typography sx={{mb: 1.2}} variant="h5"
                                                                                component="div">
                                                                        {item.discipline}
                                                                    </Typography>

                                                                    <Typography sx={{mb: 1.5}} color="text.secondary">
                                                                        {item.lecturer}
                                                                    </Typography>
                                                                    <Typography sx={{mb: 1.5}} variant="body2">
                                                                        {item.building} {Bull} {item.auditorium} {Bull} {item.auditoriumAmount} мест(а) </Typography>
                                                                    <Stack
                                                                        direction="row"
                                                                        divider={<Divider orientation="vertical"
                                                                                          flexItem/>}
                                                                        spacing={2}
                                                                    >
                                                                        <Typography
                                                                            variant="h6">{item.beginLesson}</Typography>
                                                                        <Typography
                                                                            variant="h6">{item.endLesson}</Typography>
                                                                    </Stack>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                                <Divider/>
                                            </React.Fragment>
                                        ))}
                                </TabPanel>))
                            }
                        </BindKeyboardSwipeableViews>
                    </Box>
                </MobileView>
            </React.Fragment> : <FailPage/>)
}


function sep(value) {
    if (value === undefined) {
        return 'СЕГОДНЯ'
    }

    let [day, month] = value.split('.')

    if (day.startsWith('0'))
        day = day.replaceAll('0', '')

    if (month.startsWith('0'))
        month = month.replaceAll('0', '')

    let mnts = [
        'Января',
        'Февраля',
        'Марта',
        'Апреля',
        'Мая',
        'Июня',
        'Июля',
        'Августа',
        'Сентября',
        'Октября',
        'Ноября',
        'Декабря',
    ]

    let dat = `${day} ${mnts[Number(month) - 1].toUpperCase()}`;
    if (dat === '30 ИЮНЯ' && isPaskh()) {
        return '😻30 ИЮНЯ'
    }

    return dat
}


function isPaskh() {
    if (localStorage.getItem('bars-user') === ''
        || localStorage.getItem('bars-user') === undefined
        || localStorage.getItem('bars-user') === null)
    {
        return false
    }
    return localStorage.getItem('bars-user').toUpperCase() === 'kurenkovaai'.toUpperCase()
        || localStorage.getItem('bars-user').toUpperCase() === 'riabovvo'.toUpperCase()
}

function Expander(props) {
    const {window} = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
        target: window ? window() : undefined,
    });

    return (
        trigger ? <div>Hello</div> : <div>bye</div>
    )
}


