import React from "react";
import {createTheme} from '@mui/material/styles';

export const Xtheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#1976d2',
            dark: '#1976d2',
            light: '#1976d2',
        },
        primaryD: {
            main: '#2196F3',
            dark: '#2196F3',
            light: '#2196F3',
        },
        secondaryD: {
            main: '#ea005d',
            //main: '#ef6c00',
        },
        secondary: {
            main: '#c2185b',
            //main: '#ef6c00',
        },
    },
});

export const Mattheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#c2185b',
            dark: '#c2185b',
            light: '#c2185b',
        },
        primaryD: {
            main: '#b6215c',
            dark: '#b6215c',
            light: '#b6215c',
        },
        secondary: {
            main: '#2196f3',
            //main: '#ef6c00',
        },
    },
});

export const Apelsin = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#E65100',
            dark: '#E65100',
            light: '#E65100',
        },
        primaryD: {
            main: '#E65100',
            dark: '#E65100',
            light: '#E65100',
        },
        secondary: {
            main: '#2196f3',
            //main: '#ef6c00',
        },
    },
});

export const DarkGrey = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#1e5467',
            dark: '#1e5467',
            light: '#1e5467',
        },
        primaryD: {
            main: '#3086a6',
            dark: '#3086a6',
            light: '#3086a6',
        },
        secondary: {
            main: '#2196f3',
            //main: '#ef6c00',
        },
    },
});

export const Purple = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#6002ee',
            dark: '#6002ee',
            light: '#6002ee',
        },
        primaryD: {
            main: '#a773ff',
            dark: '#a773ff',
            light: '#a773ff',
        },
        secondary: {
            main: '#c2185b',
            //main: '#ef6c00',
        },
    },
});

export const Night = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#000000',
            dark: '#1976d2',
            light: '#1976d2',
        },
        primaryD: {
            main: '#FFFFFF',
            dark: '#FFFFFF',
            light: '#FFFFFF',
        },
        secondary: {
            main: '#c2185b',
            //main: '#ef6c00',
        },
    },
});

export const NightX = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#000000',
            dark: '#1976d2',
            light: '#1976d2',
        },
        primaryD: {
            main: '#FFFFFF',
            dark: '#FFFFFF',
            light: '#FFFFFF',
        },
        lesson: {
            main: '#3E7FEA',
            dark: '#3E7FEA',
            light: '#3E7FEA',
            contrastText: '#fff',
        },
        lessonD: {
            main: '#2196F3',
            dark: '#2196F3',
            light: '#2196F3',
            contrastText: '#fff',
        },
        secondaryD: {
            main: '#ea005d',
            //main: '#ef6c00',
        },
        secondary: {
            main: '#c2185b',
            //main: '#ef6c00',
        },
    },
});

export function BaseTheme(theme) {
    theme = createTheme(theme, {
        palette: {
            mode: 'light',
            ui: {
                main: theme.palette.primary.main,
                tab: 'white',
                badge: theme.palette.primary.main
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        border: 'solid white 2px'
                    }
                }
            },
            MuiAppBar: {
                defaultProps: {
                    elevation: 0,
                },
            },
            MuiTabs: {
                defaultProps: {
                    textColor: "inherit"
                },
                styleOverrides: {
                    indicator: {
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'transparent',
                        paddingBottom: 2,
                        height: '5px',
                        minHeight: '5px',
                    }
                }
            },
        }
    })

    return theme
}

export function ExtTheme(theme) {
    theme = createTheme(theme, {
        palette: {
            code: 'bright:inverted',
            secondary: theme.palette.secondary,
            mode: 'light',
            ui: {
                main: 'white',
                tab: 'transparent',
                badge: theme.palette.primary.main
            },
            lesson: theme.palette.lesson,
            badger: 'black',
            tabShadow: '#00000030',
            cardColor: '#00000010',
            nbColor: '#FFFFFF70',
        },
        components: {
            MuiAppBar: {
                defaultProps: {
                    elevation: 0,
                    //variant: 'outlined',
                },
                styleOverrides: {
                    colorPrimary: {
                        borderTop: '0px',
                        backgroundColor: 'white',
                        color: '#292929'
                    },
                }
            },
            MuiTabs: {
                defaultProps: {
                    textColor: "inherit"
                },
                styleOverrides: {
                    indicator: {
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'transparent',
                        paddingBottom: 2,
                        height: '5px',
                        minHeight: '5px',
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 30,
                        borderColor: theme.palette.primary.main
                    }
                }
            },
        },
    })

    return theme
}

export function DarkTheme(theme) {
    let th0 = createTheme({
        palette: {
            code: 'bright',
            mode: 'dark',
            primary: theme.palette.primaryD,
            secondary: theme.palette.secondaryD ? theme.palette.secondaryD : theme.palette.secondary,
            ui: theme.palette.ui,
            background: {
                paper: '#000000',
                default: '#000000'
            },
            lesson: theme.palette.lessonD,
            badger: 'white',
            tabShadow: '#FFFFFF30',
            cardColor: '#FFFFFF10',
            nbColor: '#00000070',
        },
        components: theme.components
    })

    theme = createTheme(th0, {
        palette: {
            ui: {
                main: th0.palette.background.default,
                tab: 'white',
                badge: 'transparent'
            },
            lesson: th0.palette.lesson
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 30,
                        borderColor: theme.palette.primaryD.main
                    }
                }
            },
            MuiBadge: {
                variants: [
                    {
                        props: {variant: 'dot_e'},
                    }
                ]
            },
            MuiAppBar: {
                defaultProps: {
                    elevation: 0,
                },
            },
            MuiTabs: {
                defaultProps: {
                    textColor: "inherit"
                },
                styleOverrides: {
                    indicator: {
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'transparent',
                        paddingBottom: 2,
                        height: '5px',
                        minHeight: '5px',
                    }
                }
            },
        }
    })

    return theme
}

export const themes = {
    DEFAULT: Xtheme,
    MATERIAL: Mattheme,
    CASUAL: DarkGrey,
    PURPLE: Purple,
    NIGHT: NightX,
    APELSIN: Apelsin,
}

export const PaletteContext = React.createContext({
    palette: () => {
    },
    usePalette: (value) => {
    },
});