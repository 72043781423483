import React, {useEffect, useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Divider,
    LinearProgress,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import {DesignServices, Done, ExpandMore, MenuBook, QuestionAnswer} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {FailPage} from "./Fail";
import {isBrowser} from "react-device-detect";


const data = [
    {
        "Id": "s_ss_0",
        "DisciplineName": "ИКТ в электроэнергетике, Меренков Д.В., зачёт с оценкой, 4ЗЕ",
        "SuccessDate": "сдать до 23.12.2022",
        "Marks": [
            {
                "MarkName": "1. Ответы на вопросы по теме «Понятие энергии»",
                "MarkPrior": "15",
                "MarkTime": "15 (05.12.22-11.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "2. Решение задач по теме «Теплоэнергетика»",
                "MarkPrior": "35",
                "MarkTime": "15 (05.12.22-11.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "3. Ответы на вопросы по теме «Ядерная энергетика»",
                "MarkPrior": "15",
                "MarkTime": "15 (05.12.22-11.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "4. Решение задач по теме «Электроэнергетика»",
                "MarkPrior": "35",
                "MarkTime": "15 (05.12.22-11.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "Балл текущего контроля:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №1",
                "MarkPrior": "",
                "MarkTime": "4",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №2",
                "MarkPrior": "",
                "MarkTime": "8",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №3",
                "MarkPrior": "",
                "MarkTime": "12",
                "MarkValue": ""
            },
            {
                "MarkName": "Промежуточная аттестация (зачёт с оценкой):",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            },
            {
                "MarkName": "Итоговая оценка:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            }
        ],
        "TotalValue": ""
    },
    {
        "Id": "s_ss_1",
        "DisciplineName": "Интеллектуальные информационные системы, Карпович Е.Е., экзамен, 5ЗЕ",
        "SuccessDate": "сдать до 23.12.2022",
        "Marks": [
            {
                "MarkName": "1. КМ-1. Практическая работа 1. Разработка прототипа экспертной системы на языке SWI Prolog",
                "MarkPrior": "25",
                "MarkTime": "6 (03.10.22-09.10.22)",
                "MarkValue": "5 (31.10.22)"
            },
            {
                "MarkName": "2. КМ-2. Практические работа 2 Программирование на языке ЛИСП.",
                "MarkPrior": "20",
                "MarkTime": "10 (31.10.22-06.11.22)",
                "MarkValue": "5 (31.10.22)"
            },
            {
                "MarkName": "3. КМ-3. Практические работы 3,4. Программирования функций на языке CLIPS с использованием библиотеки стандартных функций",
                "MarkPrior": "30",
                "MarkTime": "12 (14.11.22-20.11.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "4. КМ-4. Практическая работа 5. Создание базы знаний на языке CLIPS",
                "MarkPrior": "25",
                "MarkTime": "15 (05.12.22-11.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "Балл текущего контроля:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": "5,0"
            },
            {
                "MarkName": "Контрольная неделя №1",
                "MarkPrior": "",
                "MarkTime": "4",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №2",
                "MarkPrior": "",
                "MarkTime": "8",
                "MarkValue": "0,0"
            },
            {
                "MarkName": "Контрольная неделя №3",
                "MarkPrior": "",
                "MarkTime": "12",
                "MarkValue": ""
            },
            {
                "MarkName": "Промежуточная аттестация (экзамен):",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            },
            {
                "MarkName": "Итоговая оценка:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            }
        ],
        "TotalValue": ""
    },
    {
        "Id": "s_ss_2",
        "DisciplineName": "Интеллектуальные информационные системы, Карпович Е.Е. (руководитель - Карпович Е.Е.), защита КП/КР, 1ЗЕ",
        "SuccessDate": "сдать до 23.12.2022",
        "Marks": [
            {
                "MarkName": "1. КМ-1. Утверждение темы курсовой работы. Постановка задачи",
                "MarkPrior": "20",
                "MarkTime": "5 (26.09.22-02.10.22)",
                "MarkValue": "5 (03.10.22)"
            },
            {
                "MarkName": "2. КМ-2. Подготовка теоретической части",
                "MarkPrior": "40",
                "MarkTime": "12 (14.11.22-20.11.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "3. КМ-3. Подготовка практической части КР",
                "MarkPrior": "20",
                "MarkTime": "14 (28.11.22-04.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "4. КМ-4.  Подготовка отчета КР",
                "MarkPrior": "20",
                "MarkTime": "15 (05.12.22-11.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "Балл текущего контроля:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": "5,0"
            },
            {
                "MarkName": "Контрольная неделя №1",
                "MarkPrior": "",
                "MarkTime": "4",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №2",
                "MarkPrior": "",
                "MarkTime": "8",
                "MarkValue": "5,0"
            },
            {
                "MarkName": "Контрольная неделя №3",
                "MarkPrior": "",
                "MarkTime": "12",
                "MarkValue": ""
            },
            {
                "MarkName": "Промежуточная аттестация (защита кп/кр):",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            },
            {
                "MarkName": "Итоговая оценка:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            }
        ],
        "TotalValue": ""
    },
    {
        "Id": "s_ss_3",
        "DisciplineName": "Менеджмент, Трефилова О.Л., зачёт с оценкой, 3ЗЕ",
        "SuccessDate": "сдать до 23.12.2022",
        "Marks": [
            {
                "MarkName": "1. Информация и информационные ресурсы в менеджменте",
                "MarkPrior": "20",
                "MarkTime": "10 (31.10.22-06.11.22)",
                "MarkValue": "5 (05.11.22)"
            },
            {
                "MarkName": "2. Построение организационных структур",
                "MarkPrior": "30",
                "MarkTime": "11 (07.11.22-13.11.22)",
                "MarkValue": "4 (05.11.22)"
            },
            {
                "MarkName": "3. Управленческие решения в системе менеджмента",
                "MarkPrior": "30",
                "MarkTime": "12 (14.11.22-20.11.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "4. Основы деловых отношений",
                "MarkPrior": "20",
                "MarkTime": "14 (28.11.22-04.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "Балл текущего контроля:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": "4,4"
            },
            {
                "MarkName": "Контрольная неделя №1",
                "MarkPrior": "",
                "MarkTime": "4",
                "MarkValue": "0,0"
            },
            {
                "MarkName": "Контрольная неделя №2",
                "MarkPrior": "",
                "MarkTime": "8",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №3",
                "MarkPrior": "",
                "MarkTime": "12",
                "MarkValue": ""
            },
            {
                "MarkName": "Промежуточная аттестация (зачёт с оценкой):",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            },
            {
                "MarkName": "Итоговая оценка:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            }
        ],
        "TotalValue": ""
    },
    {
        "Id": "s_ss_4",
        "DisciplineName": "Организация и программное обеспечение информационных сетей и систем, Меренков Д.В., экзамен, 6ЗЕ",
        "SuccessDate": "сдать до 23.12.2022",
        "Marks": [
            {
                "MarkName": "1. Выполнение лабораторного модуля по конфигурации сетевой среды",
                "MarkPrior": "25",
                "MarkTime": "15 (05.12.22-11.12.22)",
                "MarkValue": "5 (09.11.22)"
            },
            {
                "MarkName": "2. Выполнение лабораторного модуля по созданию и управлению инфраструктурой сетевой среды",
                "MarkPrior": "25",
                "MarkTime": "15 (05.12.22-11.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "3. Выполнение лабораторного модуля по настройке компонентов сетевой среды",
                "MarkPrior": "25",
                "MarkTime": "15 (05.12.22-11.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "4. Выполнение лабораторного модуля по применению сетевого программного обеспечения",
                "MarkPrior": "25",
                "MarkTime": "15 (05.12.22-11.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "Балл текущего контроля:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №1",
                "MarkPrior": "",
                "MarkTime": "4",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №2",
                "MarkPrior": "",
                "MarkTime": "8",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №3",
                "MarkPrior": "",
                "MarkTime": "12",
                "MarkValue": ""
            },
            {
                "MarkName": "Промежуточная аттестация (экзамен):",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            },
            {
                "MarkName": "Итоговая оценка:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            }
        ],
        "TotalValue": ""
    },
    {
        "Id": "s_ss_5",
        "DisciplineName": "Проектный практикум, Петров С.А., экзамен, 4ЗЕ",
        "SuccessDate": "сдать до 23.12.2022",
        "Marks": [
            {
                "MarkName": "1. Тест: Основы управления проектами. Инициация программного проекта. Разработка и анализ требований к ПО. Проектирование и архитектура ПО.",
                "MarkPrior": "20",
                "MarkTime": "6 (05.10.22)",
                "MarkValue": "4 (08.10.22)"
            },
            {
                "MarkName": "2. Тест: Управление содержанием и сроками программного проекта. Управление человеческими ресурсами программного проекта. Отладка и тестирование ПО. Документирование ПО.",
                "MarkPrior": "20",
                "MarkTime": "8 (19.10.22)",
                "MarkValue": "5 (22.10.22)"
            },
            {
                "MarkName": "3. Защита ПР: Анализ предметной области и сбор требований. Оценка перспективности методом экспертных оценок. CVP-Анализ. Разработка приложений в Microsoft Visual Studio С#. Модульное тестирование. Разработка схемы базы данных, генерация скриптов для создания структуры БД.",
                "MarkPrior": "30",
                "MarkTime": "11 (02.11.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "4. Защита ПР:\tРазработка структурной декомпозиция работ. Разработка сетевого графика проекта, метод критического пути. Реализация базовых операций по изменению данных с помощью Entity Framework.",
                "MarkPrior": "30",
                "MarkTime": "12 (16.11.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "Балл текущего контроля:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": "2,6"
            },
            {
                "MarkName": "Контрольная неделя №1",
                "MarkPrior": "",
                "MarkTime": "4",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №2",
                "MarkPrior": "",
                "MarkTime": "8",
                "MarkValue": "4,5"
            },
            {
                "MarkName": "Контрольная неделя №3",
                "MarkPrior": "",
                "MarkTime": "12",
                "MarkValue": ""
            },
            {
                "MarkName": "Промежуточная аттестация (экзамен):",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            },
            {
                "MarkName": "Итоговая оценка:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            }
        ],
        "TotalValue": ""
    },
    {
        "Id": "s_ss_6",
        "DisciplineName": "Проектный практикум, Петров С.А. (руководитель - Петров С.А.), защита КП/КР, 1ЗЕ",
        "SuccessDate": "сдать до 23.12.2022",
        "Marks": [
            {
                "MarkName": "1. Своевременный выбор темы, ориентация в выбранной предметной области",
                "MarkPrior": "30",
                "MarkTime": "8 (20.10.22)",
                "MarkValue": "5 (11.10.22)"
            },
            {
                "MarkName": "2. Соблюдение графика выполнения, качество оформления КР, уровень проработки прототипа ИС, антиплагиат",
                "MarkPrior": "70",
                "MarkTime": "16 (17.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "Балл текущего контроля:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": "5,0"
            },
            {
                "MarkName": "Контрольная неделя №1",
                "MarkPrior": "",
                "MarkTime": "4",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №2",
                "MarkPrior": "",
                "MarkTime": "8",
                "MarkValue": "5,0"
            },
            {
                "MarkName": "Контрольная неделя №3",
                "MarkPrior": "",
                "MarkTime": "12",
                "MarkValue": ""
            },
            {
                "MarkName": "Промежуточная аттестация (защита кп/кр):",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            },
            {
                "MarkName": "Итоговая оценка:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            }
        ],
        "TotalValue": ""
    },
    {
        "Id": "s_ss_7",
        "DisciplineName": "Производственная практика: научно-исследовательская работа, Еремеев А.А. (руководитель - Еремеев А.А.), зачёт с оценкой, 7ЗЕ",
        "SuccessDate": "сдать до 23.12.2022",
        "Marks": [
            {
                "MarkName": "Балл текущего контроля:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №1",
                "MarkPrior": "",
                "MarkTime": "4",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №2",
                "MarkPrior": "",
                "MarkTime": "8",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №3",
                "MarkPrior": "",
                "MarkTime": "12",
                "MarkValue": ""
            },
            {
                "MarkName": "Промежуточная аттестация (зачёт с оценкой):",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            },
            {
                "MarkName": "Итоговая оценка:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            }
        ],
        "TotalValue": ""
    },
    {
        "Id": "s_ss_8",
        "DisciplineName": "финансы, Бабич И.С., зачёт с оценкой, 3ЗЕ",
        "SuccessDate": "сдать до 23.12.2022",
        "Marks": [
            {
                "MarkName": "1. КМ-1\nсущность финансов",
                "MarkPrior": "50",
                "MarkTime": "8 (17.10.22-23.10.22)",
                "MarkValue": "4 (18.10.22)"
            },
            {
                "MarkName": "2. КМ-2\nфинансовая информация",
                "MarkPrior": "50",
                "MarkTime": "15 (05.12.22-11.12.22)",
                "MarkValue": ""
            },
            {
                "MarkName": "Балл текущего контроля:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": "4,0"
            },
            {
                "MarkName": "Контрольная неделя №1",
                "MarkPrior": "",
                "MarkTime": "4",
                "MarkValue": ""
            },
            {
                "MarkName": "Контрольная неделя №2",
                "MarkPrior": "",
                "MarkTime": "8",
                "MarkValue": "4,0"
            },
            {
                "MarkName": "Контрольная неделя №3",
                "MarkPrior": "",
                "MarkTime": "12",
                "MarkValue": ""
            },
            {
                "MarkName": "Промежуточная аттестация (зачёт с оценкой):",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            },
            {
                "MarkName": "Итоговая оценка:",
                "MarkPrior": "",
                "MarkTime": "",
                "MarkValue": ""
            }
        ],
        "TotalValue": ""
    }
]

function checkColor(value) {
    switch (value) {
        case 'Лекция':
            return 'primary'
        case 'Практическое занятие':
            return 'secondary'
        case 'Консультация':
            return 'warning'
    }
}

function setColor(value, theme) {
    switch (value) {
        case 'Лекция':
            return theme.palette.primary.main
        case 'Практическое занятие':
            return theme.palette.secondary.main
        case 'Консультация':
            return theme.palette.warning.main
    }
}

function setIcon(value) {
    switch (value) {
        case 'Лекция':
            return <MenuBook/>
        case 'Практическое занятие':
            return <DesignServices/>
        case 'Консультация':
            return <QuestionAnswer/>
    }
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('.');
}

function convertToDate(date, end) {
    let dateparts = date.split(".");
    var datestring = dateparts[0] + "/" + dateparts[1] + "/" + dateparts[2] + " " + end;
    let resDate = new Date(datestring);

    return resDate
}

function groupBy(array) {
    let result = array.reduce((r, {discipline: name, ...object}) => {
        var temp = r.find(o => o.name === name);
        if (!temp) r.push(temp = {name, children: []});
        //object.date = convertToDate(object.date, object.endLesson)
        temp.children.push(object);
        return r;
    }, []);

    return result
}

function groupByWork(array) {
    let result = array.reduce((r, {kindOfWork: name, ...object}) => {
        var temp = r.find(o => o.name === name);
        if (!temp) r.push(temp = {name, children: []});
        temp.children.push(object);
        return r;
    }, []);

    return result
}

const ekz = [
    "Проектный практикум",
    "Интеллектуальные информационные системы",
    "Организация и программное обеспечение информационных сетей и систем"
]

function getCurrentValue(value) {
    if (localStorage.getItem('days') === 'true') {
        return value / 2
    }

    return value
}

export function BarsPage() {
    const theme = useTheme();
    const [isLoading, setLoading] = useState(true)
    const [items, setItems] = useState({});
    const [failed, setFailed] = useState(false)
    const [expanded, setExpanded] = useState(false)


    useEffect(() => {
        fetch(`bars/get_marks`, {
            body: localStorage.getItem('token'),
            method: 'POST'
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setLoading(false)
                    setItems(result)
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    setFailed(true)
                    console.log(error)
                }
            )
    }, [])

    if (failed) {
        return (
            <FailPage type="bars" kind="bars"/>
        )
    }

    const card = localStorage.getItem('desCard') ? localStorage.getItem('desCard') : 'IOS'

    return (
        localStorage.getItem('groupId') !== null ?
            <Box sx={{flexGrow: 1, minHeight: '100%'}}>
                <AppBar position="sticky">
                    {isBrowser ? null : <Toolbar>
                        <Typography variant="h4" fontWeight={'bold'} component="div">
                            БАРС
                        </Typography>
                    </Toolbar>}
                </AppBar>
                {isLoading ? <div style={{
                        display: 'flex', justifyContent: 'center',
                        marginTop: '10vh'
                    }}><CircularProgress/></div> :
                    items === {} ? null : <div>
                        <Stack>
                            <Stack sx={{mx: 1, my: 2}} direction={"column"} spacing={1}>
                                <Typography variant={"button"}>КМ
                                    пройдено {items.gettedMarks}/{items.allMarks}</Typography>
                                <LinearProgress color={'lesson'} sx={card === 'IOS' ? {height: 7, borderRadius: '10px'} : {}}
                                                value={parseInt(items.gettedMarks) / parseInt(items.allMarks) * 100}
                                                variant={"determinate"}/>
                            </Stack>
                            {card === 'IOS' ? null : <Divider/>}
                            <div style={{
                                marginLeft: 10,
                                marginRight: 10,
                                marginBottom: 10,
                                marginTop: 10,
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <Typography align={'center'} style={{alignContent: 'center'}} variant={"button"}>Ср.
                                    балл {items.averageMarkSemestr}</Typography>
                                {card === 'IOS' ? <Button color={'primary'} disableRipple disableTouchRipple disableFocusRipple
                                                          sx={
                                                              {
                                                                  fontWeight: 'bold',
                                                                  paddingY: 0.5,
                                                                  paddingX: 2,
                                                              }
                                                          } disableElevation
                                                          variant={"contained"}
                                                          onClick={() => setExpanded(!expanded)}>{!expanded ? 'Развернуть все' : 'Свернуть всё'}</Button>
                                    : <Button
                                        onClick={() => setExpanded(!expanded)}>{!expanded ? 'Развернуть все' : 'Свернуть всё'}</Button>}
                            </div>
                        </Stack>
                        {items.disciplines.map((value) => {
                                if (!value.Ended) {
                                    return card === 'IOS' ? <XAccI exp={expanded} value={value}/> :
                                        <XAcc exp={expanded} value={value}/>
                                }
                            }
                        )}
                        <Divider sx={{my: 2}}/>
                        {items.disciplines.map((value) => {
                                if (value.Ended) {
                                    return card === 'IOS' ? <XAccI exp={expanded} value={value}/> :
                                        <XAcc exp={expanded} value={value}/>
                                }
                            }
                        )}
                    </div>}
            </Box>
            :
            <FailPage/>
    )
}

function XAcc(props) {
    const {value, exp} = props;
    const [expanded, setExpanded] = useState(exp)

    useEffect(() => {
        setExpanded(exp);
    }, [exp])

    return (
        <Accordion onClick={() => setExpanded(!expanded)} expanded={expanded} sx={() => {
            const ss = {borderRadius: '0px', margin: '0px',}

            if (value.DisciplineName.includes('практика')) {
                return {borderLeft: '3px #00897b solid', ...ss}
            }

            if (value.DisciplineName.includes('зачёт')) {
                return {borderLeft: '3px #1976d2 solid', ...ss}
            }

            if (value.DisciplineName.includes('экзамен')) {
                return {borderLeft: '3px #fb8c00 solid', ...ss}
            }

            if (value.DisciplineName.includes('КП')) {
                return {borderLeft: '3px #d81b60 solid', ...ss}
            }
        }}>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Stack direction={"column"}>
                    <Typography sx={value.Ended ? {
                        color: 'grey',
                        //textDecoration: 'line-through'
                    } : {fontWeight: 'bold'}}>{value.DisciplineName}</Typography>
                    <Typography sx={{color: 'text.secondary'}}>{value.SuccessDate}</Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Название КМ</TableCell>
                            <TableCell>Дата сдачи</TableCell>
                            <TableCell>Оценка</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {value.Marks.map((mark) =>
                            <TableRow>
                                <TableCell>{mark.MarkName}</TableCell>
                                <TableCell>{mark.MarkTime}</TableCell>
                                <TableCell>{mark.MarkValue}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    )
}

function XAccI(props) {
    const {value, exp} = props;
    const [expanded, setExpanded] = useState(exp)
    const theme = useTheme()

    useEffect(() => {
        setExpanded(exp);
    }, [exp])

    return (
        <Accordion elevation={expanded ? 5 : 0} onClick={() => setExpanded(!expanded)} expanded={expanded} sx={() => {
            const ss = {
                //border: `${expanded ? 0 : 1}px solid ${theme.palette.divider}`,
                borderRadius: expanded ? '0px' : '0px', margin: '10px',
                transition: 'all 0.2s ease-in',

                '&:before': {
                    display: 'none'
                },
            }

            let ex = 0
            let unEx = 5


            if (value.DisciplineName.includes('практика')) {
                return {borderLeft: `${expanded ? ex : unEx}px #00897b solid !important`, ...ss}
            }

            if (value.DisciplineName.includes('зачёт')) {
                return {borderLeft: `${expanded ? ex : unEx}px #1976d2 solid !important`, ...ss}
            }

            if (value.DisciplineName.includes('экзамен')) {
                return {borderLeft: `${expanded ? ex : unEx}px #fb8c00 solid !important`, ...ss}
            }

            if (value.DisciplineName.includes('КП')) {
                return {borderLeft: `${expanded ? ex : unEx}px #d81b60 solid !important`, ...ss}
            }
        }}>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Stack direction={"column"}>
                    <Typography sx={value.Ended ? {
                        color: 'grey',
                        //textDecoration: 'line-through'
                    } : {fontWeight: 'bold'}}>{value.DisciplineName}</Typography>
                    <Typography sx={{color: 'text.secondary'}}>{value.SuccessDate}</Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Название КМ</TableCell>
                            <TableCell>Дата сдачи</TableCell>
                            <TableCell>Оценка</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {value.Marks.map((mark) =>
                            <TableRow>
                                <TableCell>{mark.MarkName}</TableCell>
                                <TableCell>{mark.MarkTime}</TableCell>
                                <TableCell>{mark.MarkValue}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    )
}

function GetCurrent(items) {
    let date = new Date()
    return items.filter(value => {
        return value.date < date;
    })
}

function StatElement(props) {
    const theme = useTheme()
    const [open, setOpen] = useState(true)

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <React.Fragment>
            <ListItemButton onClick={handleClick}>

                <ListItemText primary={<div><b>{props.disc.name}</b> <a
                    style={{opacity: 0.7}}>{ekz.includes(props.disc.name) ? '(экзамен)' : null}</a></div>}/>
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {
                    groupByWork(props.disc.children).map(item => {
                        const curr = GetCurrent(item.children).length / item.children.length * 100
                        return <List disablePadding>
                            <ListItemButton>
                                <ListItemAvatar>
                                    <Avatar sx={
                                        {bgcolor: curr === 100 ? theme.palette.success.light : setColor(item.name, theme)}}>
                                        {curr === 100 ? <Done/> : setIcon(item.name)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${item.name} ${getCurrentValue(GetCurrent(item.children).length)}/${getCurrentValue(item.children.length)}`}
                                    secondary={<LinearProgress variant="determinate"
                                                               value={curr}/>}/>

                            </ListItemButton>
                        </List>
                    })
                }
            </Collapse>
            <Divider/>
        </React.Fragment>
    )
}
