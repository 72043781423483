import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {EditNotifications} from "@mui/icons-material";
import {Fab, ListItemButton, Snackbar} from "@mui/material";
import LinearDeterminate from "./Progress";
import HelloCard from "./HelloCard";
import {AddNotifyDialog} from "./NotifyDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function FullScreenDialog() {
    const [open, setOpen] = React.useState(false);
    const [snacked, setSnacked] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Fab onClick={() => handleClickOpen()} size={"large"} sx={{
                position: 'fixed',
                marginBottom: 'env(safe-area-inset-bottom, 10px)',
                bottom: 70,
                right: 16,
            }} color="primary"><EditNotifications/>
            </Fab>
            <Snackbar
                onClose={() => setSnacked(false)}
                sx={{
                    marginBottom: 'env(safe-area-inset-bottom, 10px)',
                    }
                }
                open={snacked}
                autoHideDuration={1000}
                message="Саша, не трогай, это в разработке :("
            />
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Заметки
                        </Typography>
                        { false ?
                            <Button sx={{borderWidth: 0}} color="inherit" onClick={() => {
                                setSnacked(true)
                            }}>
                                добавить
                            </Button> : null
                        }
                        <AddNotifyDialog/>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItemButton>
                        <ListItemText primary="Однако хороший день, не правда?" secondary="30.06.1999" />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton>
                        <ListItemText
                            primary="Ужос! Просто ужос! Очка."
                            secondary="01.09.2022"
                        />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton>
                        <ListItemText
                            primary="Сдать финансы"
                            secondary="16.09.2022"
                        />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton>
                        <ListItemText
                            primary="А еще это статический код :("
                            secondary="16.09.2022"
                        />
                    </ListItemButton>
                </List>
            </Dialog>
        </div>
    );
}

export function ReynoldsDialog() {
    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    setTimeout(() => setOpen(false), 4000)

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <LinearDeterminate/>
                    </Toolbar>
                </AppBar>
                <HelloCard/>
            </Dialog>
        </div>
    );
}