import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Stack, Typography} from "@mui/material";
import React from "react";
import {Book, Bookmark, MenuBook, Refresh} from "@mui/icons-material";
import {Bull} from "./Components/Bull";

const version = {
    current: 'v4.0.0',
    changelog: [
        {
            version: 'v3.4.0',
            desc: ['Добавлен день недели в ближайшеие'
            ]
        },
        {
            version: 'v3.3.0',
            desc: ['Темная тема карт'
            ]
        },
        {
            version: 'v3.1.0',
            desc: ['Открыт исходный код, автоматическая регистрация образа'
            ]
        },
        {
            version: 'v3.0.0',
            desc: ['Новый цельный UI',
            'Добавлен десктоп режим'
            ]
        },
        {
            version: 'v2.1.0',
            desc: ['Обновление интерфейса'
            ]
        },
        {
            version: 'v2.0.1b',
            desc: ['Исправлены мелкие ошибки'
            ]
        },
        {
            version: 'v2.0.0b',
            desc: ['Добавлена интеграция с БАРС',
                'Внимание! Вы передаёте данные на личный сервер разработчика.',
                'Пароли передаваемые на сервер шифруются и не сохраняются на бэкенде, а проксируются в bars.mpei.ru'
            ]
        },
        {
            version: 'v2.0.0a',
            desc: ['Подключен пакет контроля темами.',
                'Добавлены новые настройки: оформление и настройка отражения сводки.',
                'Теперь в сводке отражается статус окончания пар.',
                'Карты теперь в одном стиле с темой.',
                'Частичные оптимизации.',
                'Подготовка к релизу версии 2.0',
                'Независимое обновление. Создан отдельный инстанс. ',
                'Версия 1.5.0 является самой стабильной и не подлежит обновлению на старом порте.',
                'Обновлен цвет FAB компоненты.',
                'Добавлен инструмент для обновления (в разделе "Обновления").',
                'Прошу главную тестировщицу, Сашу Куренкову, подготовиться к испытаниям :|'
            ]
        },
        {
            version: 'v1.5.0',
            desc: [
                'Добавлена настройка отражения сводки в днях'
            ]
        },
        {
            version: 'v1.4.1',
            desc: [
                'Исправлены margins для IphoneX форм-фактора'
            ]
        },
        {
            version: 'v1.4.0',
            desc: [
                'Добавлен сплэш при запуске',
                'Если текущий день воскресенье, то расписание автоматически перейдет на след. неделю',
                'При переключение на след/пред недели, автоматом переносится на вкладку понедельника',
                'Обновлены страницы ошибок',
                'Обновлена страница «пар нет»',
                'Добавлена настройка «считать сводку в днях, а не в парах» (делить все часы на 2)',
                'Добавлена индикация загрузки',
                'Текст больше не выделяется нигде (работает как нативное приложение)',
                'Вкладки "Сегодня" больше нет (на самом деле есть) — переименована в статус, новая иконка',
                'Теперь если текущий день Воскресенье, то вкладка "Сегодня" станет вкладкой "Завтра" и будет ссылаться на понедельник',
                'Теперь приложение само определяет у тебя классический айфон или без кнопки и подгоняет размер навбара.',
                'Обновлена иконка (увеличен padding)',
                'Для корректной работы желательно переустановить приложение:('
            ]
        },
        {
            version: 'v1.3.0',
            desc: [
                'Добавлена сводка'
            ]
        },
        {
            version: 'v1.2.2',
            desc: [
                'Мелкие исправления'
            ]
        },
        {
            version: 'v1.2.2',
            desc: [
                'Мелкие исправления'
            ]
        },
        {
            version: 'v1.2.1',
            desc: [
                'Мелкие исправления'
            ]
        },
        {
            version: 'v1.2.0',
            desc: [
                'Добавлена карта'
            ]
        },
        {
            version: 'v1.1.0',
            desc: [
                'Перенесена система деплоя на Docker'
            ]
        },
        {
            version: 'v1.0.0',
            desc: [
                'Первый релиз'
            ]
        },
    ]
}

function forceSWupdate() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (let registration of registrations) {
                registration.update()
            }
        })
    }
}

export function VersionView() {
    return (
        <Box sx={{mb: 5,}}>
            <Stack direction={"column"}>
                <Typography sx={{mb: 2, mt: 2, mx: 2}} variant="h6"
                            color="text.secondary">Версия: {version.current}</Typography>
                <Button disableElevation sx={{mb: 2, mt: 2, mx: 2}} variant={"contained"}
                        onClick={() => {
                            forceSWupdate();
                            window.location.reload()
                        }}><Typography variant={"h6"}>Обновить</Typography></Button>
            </Stack>
            <Divider sx={{mb: 2}}/>
            {
                version.changelog.map(value => <ChangeLogView version={value.version} desc={value.desc}/>)
            }
        </Box>
    )
}

function ChangeLogView(props) {
    return (
        <React.Fragment>
            <Accordion expanded={true}>
                <AccordionSummary

                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant={'h6'}>{props.version}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {props.desc.map(value => (
                        <Typography>
                            {Bull} {value}
                        </Typography>))}
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    )
}
