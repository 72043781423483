import React from 'react'
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {ArrowForwardIosSharp, CalendarToday} from "@mui/icons-material";
import {styled} from "@mui/material/styles";

export const XAccordion = styled((props) => (
    <Accordion TransitionProps={{ unmountOnExit: true, timeout: 500 }} disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `0px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    width: '100%'
}));

export const XAccordionSummary = styled((props) => (
    <AccordionSummary
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'inherit',
    border: 0,
    color: 'inherit',
    //flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'inherit'
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
        display: 'block',
        alignContent: 'center',
    },
}));

export const XAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: 'inherit',
    transition: 'all 0.3s ease-in',
    //borderTop: '2px solid white',
    //borderBottom: '2px solid white',
    '& .MuiAccordionDetails-root.Mui-expanded': {
        display: 'block',
        alignContent: 'center',
        backgroundColor: 'red'
    },
}));