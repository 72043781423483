import React, {useEffect, useState} from 'react'
import {
    AppBar,
    Avatar,
    Box,
    Chip,
    CircularProgress,
    Collapse,
    Divider,
    Grid,
    LinearProgress,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Stack,
    Typography,
    Slide,
    Dialog, Toolbar
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {StyledTab2, StyledTab2Sel, StyledTabs, TabPanel} from "../Components/Tab";
import {Close, DesignServices, Done, DoneAll, Edit, MenuBook, QuestionAnswer} from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import {useTheme} from "@mui/material/styles";
import {FailPage} from "./Fail";
import {StyledBadgeDefault, StyledBadgePrimary, StyledBadgeSecondary, StyledBadgeWarning} from "../Components/Badge";
import {BrowserView, MobileView} from "react-device-detect"
import {
    BsBookHalf,
    BsBookmarkCheckFill,
    BsFillChatSquareQuoteFill,
    BsFillPenFill,
    BsListCheck,
    BsPencilFill
} from "react-icons/bs";
import IconButton from "@mui/material/IconButton";

function checkColor(value) {
    switch (value) {
        case 'Лекция':
            return 'lesson'
        case 'Практическое занятие':
            return 'secondary'
        case 'Консультация':
            return 'warning'
    }
}

function setColor(value, theme) {
    switch (value) {
        case 'Лекция':
            return theme.palette.lesson.main
        case 'Практическое занятие':
            return theme.palette.secondary.main
        case 'Консультация':
            return theme.palette.warning.main
        case 'Зачет с оценкой (по совокупности результатов текущего контроля)':
            return theme.palette.text.primary
        case 'Экзамен':
            return theme.palette.text.primary
    }
}

function setIcon(value, card) {
    switch (value) {
        case 'Лекция':
            return card === 'IOS' ? <BsBookHalf/> : <MenuBook/>
        case 'Практическое занятие':
            return card === 'IOS' ? <BsPencilFill/> : <DesignServices/>
        case 'Консультация':
            return card === 'IOS' ? <BsFillChatSquareQuoteFill/> : <QuestionAnswer/>
        case 'Зачет с оценкой (по совокупности результатов текущего контроля)':
            return card === 'IOS' ? <BsBookmarkCheckFill/> : <QuestionAnswer/>
        case 'Экзамен':
            return card === 'IOS' ? <BsFillPenFill/> : <QuestionAnswer/>
        default:
            return <Edit/>
    }
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('.');
}

function getToday() {
    var d = new Date(),
        month = d.getMonth(),
        day = '' + d.getDate();

    let dayName = d.toLocaleDateString('ru-RU', { weekday: 'long' });

    if (day.length < 2)
        day = '0' + day;

    let mnts = [
        'Января',
        'Февраля',
        'Марта',
        'Апреля',
        'Мая',
        'Июня',
        'Июля',
        'Августа',
        'Сентября',
        'Октября',
        'Ноября',
        'Декабря',
    ]

    return `${day} ${mnts[month].toUpperCase()} | ${dayName}`;
}

function formatPreviewDate(date) {
    var d = new Date(date),
        month = d.getMonth(),
        day = '' + d.getDate();

    let dayName = date.toLocaleDateString('ru-RU', { weekday: 'long' });

    if (day.length < 2)
        day = '0' + day;

    let mnts = [
        'Января',
        'Февраля',
        'Марта',
        'Апреля',
        'Мая',
        'Июня',
        'Июля',
        'Августа',
        'Сентября',
        'Октября',
        'Ноября',
        'Декабря',
    ]

    return `${day} ${mnts[month].toUpperCase()} | ${dayName}`;
}


function convertToDate(date, end) {
    let dateparts = date.split(".");
    var datestring = dateparts[0] + "/" + dateparts[1] + "/" + dateparts[2] + " " + end;
    let resDate = new Date(datestring);

    return resDate
}

function groupBy(array) {
    let result = array.reduce((r, {discipline: name, ...object}) => {
        var temp = r.find(o => o.name === name);
        if (!temp) r.push(temp = {name, children: []});
        //object.date = convertToDate(object.date, object.endLesson)
        temp.children.push(object);
        return r;
    }, []);

    return result
}

function groupByWork(array) {
    let result = array.reduce((r, {kindOfWork: name, ...object}) => {
        var temp = r.find(o => o.name === name);
        if (!temp) r.push(temp = {name, children: []});
        temp.children.push(object);
        return r;
    }, []);

    return result
}

function groupByDate(array) {
    let result = array.reduce((r, {date: name, ...object}) => {
        var temp = r.find(o => o.name === name);
        if (!temp) r.push(temp = {name, children: []});
        temp.children.push(object);
        return r;
    }, []);

    return result
}

const ekz = [
    "Проектный практикум",
    "Интеллектуальные информационные системы",
    "Организация и программное обеспечение информационных сетей и систем"
]

function getCurrentValue(value) {
    if (localStorage.getItem('days') === 'true') {
        return value / 2
    }

    return value
}

function Dots(value) {
    switch (value) {
        case 'Лекция':
            return <StyledBadgePrimary variant="dot" color="primary"/>
        case 'Практическое занятие':
            return <StyledBadgeSecondary variant="dot" color="primary"/>
        case 'Консультация':
            return <StyledBadgeWarning variant="dot" color="primary"/>
        default:
            return <StyledBadgeDefault variant="dot" color="primary"/>
    }
}

export function TodayPage() {
    let ddd = [
        `ПН`,
        `ВТ`,
        `СР`,
        `ЧТ`,
        `ПТ`,
        `СБ`,
    ]

    const theme = useTheme()

    const [isLoading, setLoading] = useState(true)
    const [items, setItems] = useState([]);
    const [stats, setStats] = useState([]);
    const [all, setAll] = useState([]);
    const [tab, setTab] = useState(0);
    const [failed, setFailed] = useState(false)


    const _today = new Date()

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };


    useEffect(() => {
        let today = new Date()
        if (today.getDay() === 0) {
            today.setDate(today.getDate() + 1)
        }

        let date = formatDate(today)
        let date1 = formatDate(today.setDate(today.getDate() + 30))

        fetch(`api/schedule/group/${localStorage.getItem('groupId')}?start=${date}&finish=${date1}&lng=1`, {})
            .then(res => res.json())
            .then(
                (result) => {
                    result.map((value, index) => {
                        result[index].date = formatPreviewDate(convertToDate(value.date, value.endLesson))
                    })
                    setItems(groupByDate(result))
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    console.log(error)
                }
            )

        let curDate = new Date();

        fetch(`api/schedule/group/${localStorage.getItem('groupId')}?start=${curDate.getFullYear()}.02.01&finish=${curDate.getFullYear()}.07.23&lng=1`, {})
            .then(res => res.json())
            .then(
                (result) => {
                    result.map((value, index) => {
                        result[index].date = convertToDate(value.date, value.endLesson)
                    })
                    setAll(result)
                    let res = groupBy(result)
                    setStats(res)
                    setLoading(false)
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    console.log(error)
                    setFailed(true)
                }
            )
    }, [])

    if (failed) {
        return (
            <FailPage type="fail"/>
        )
    }

    const card = localStorage.getItem('desCard') ? localStorage.getItem('desCard') : 'IOS'

    return (
        localStorage.getItem('groupId') !== null ?
            <React.Fragment>
                <MobileView>
                <Box sx={{flexGrow: 1, minHeight: '100%'}}>
                    <AppBar position="sticky">
                        <Box sx={{marginX: '30px'}}>
                            <StyledTabs
                                hide
                                variant={"fullWidth"}
                                centered
                                value={tab}
                                onChange={handleChange}
                                indicatorColor="primary"
                                aria-label="full width tabs example"
                            >
                                <StyledTab2
                                    label={'БЛИЖАЙШЕЕ'}/>
                                <StyledTab2 label="СВОДКА"/>
                            </StyledTabs>
                        </Box>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={tab}
                        onChangeIndex={setTab}>
                        <TabPanel value={tab} index={0} dir={theme.direction}>
                            {
                                items.length === 0 ?
                                    <React.Fragment>
                                        <div style={{minHeight: '100%'}}>
                                            <div style={{
                                                display: 'flex', justifyContent: 'center',
                                                paddingTop: '25vh'
                                            }}>
                                                <DoneAll sx={{fontSize: '15vh', color: theme.palette.lesson.main}}/>
                                            </div>
                                            <div style={{
                                                display: 'flex', justifyContent: 'center',
                                                paddingTop: '5vh'
                                            }}>
                                                <Typography variant={'h6'}><b>ПАРЫ ОТСУТСТВУЮТ</b></Typography>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {
                                            items[0].name !== getToday()
                                                ? <div style={{minHeight: '10%'}}>
                                                    <div style={{
                                                        display: 'flex', justifyContent: 'center',
                                                        paddingTop: '20px'
                                                    }}>
                                                        <DoneAll sx={{fontSize: '7vh', color: theme.palette.lesson.main}}/>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex', justifyContent: 'center',
                                                        paddingTop: '10px'
                                                    }}>
                                                        <Typography variant={'body1'}><b>СЕГОДНЯ ПАР НЕТ</b></Typography>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        {items.map(block => (
                                            <React.Fragment>
                                                <Typography sx={{ml: 3, mt: 3}} variant={'h6'} fontWeight={'bold'}>
                                                    {getToday() === block.name ? 'СЕГОДНЯ' : `${block.name}`}
                                                </Typography>
                                                {block.children.map(item =>
                                                    <Card variant={"outlined"} elevation={10} sx={
                                                        card === 'IOS' ?
                                                        {
                                                        backgroundColor: 'transparent',
                                                        m: 1,
                                                        minWidth: 200,
                                                        padding: 0,
                                                        borderLeft: '10px',
                                                        borderRight: '10px',
                                                        borderTop: '10px',
                                                    }
                                                    : {
                                                        backgroundColor: theme.palette.cardColor,
                                                        m: 1,
                                                        borderRadius: '10px',
                                                        minWidth: 200,
                                                        padding: 0,
                                                        border: '10px'
                                                    }}>
                                                        <Grid container alignItems="center">
                                                            <Grid sx={{textAlign: 'center'}} item xs={1}>
                                                                <h2>{item.contentTableOfLessonsName}</h2>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <CardContent>
                                                                    <Stack direction="row"
                                                                           spacing={1}>
                                                                        <Chip variant={card === 'IOS'  ? 'outlined' : 'filled'} sx={
                                                                            card === 'IOS' ?
                                                                            {mb: 1, border: '10px', fontWeight: 'bold', backgroundColor: theme.palette.cardColor}
                                                                            : {mb: 1, border: '10px'}
                                                                        } label={card === 'IOS' ? item.kindOfWork.toUpperCase() : item.kindOfWork}
                                                                              color={checkColor(item.kindOfWork)}/>
                                                                    </Stack>

                                                                    <Typography sx={{mb: 1}} variant="body1"
                                                                                component="div">
                                                                        {item.discipline}
                                                                    </Typography>

                                                                    <Typography sx={{mb: 1}} color="text.secondary">
                                                                        {item.lecturer}
                                                                    </Typography>
                                                                    <Stack
                                                                        direction="row"
                                                                        divider={<Divider orientation="vertical"
                                                                                          flexItem/>}
                                                                        spacing={2}
                                                                    >
                                                                        <Typography fontWeight={"bold"}
                                                                                    variant="body2">{item.beginLesson}</Typography>
                                                                        <Typography fontWeight={'bold'}
                                                                                    variant="body2">{item.endLesson}</Typography>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>)}
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>}
                        </TabPanel>
                        <TabPanel value={tab} index={1} dir={theme.direction}>
                            {
                                isLoading ? <div style={{
                                        display: 'flex', justifyContent: 'center',
                                        marginTop: '10vh'
                                    }}><CircularProgress/></div> :
                                    <List>
                                        {localStorage.getItem('top') === 'true' ?
                                            <React.Fragment>
                                                <ListItemButton>
                                                    <ListItemText primary={
                                                        <b>{`Всего ${getCurrentValue(GetCurrent(all).length)}/${getCurrentValue(all.length)}`}</b>}
                                                                  secondary={<LinearProgress color={'lesson'}  sx={card === 'IOS' ? {height: 7, borderRadius: '10px'} : {}} variant="determinate"
                                                                                             value={GetCurrent(all).length / all.length * 100}/>}/>
                                                </ListItemButton>
                                                <Collapse in={true} timeout="auto" unmountOnExit>
                                                    {
                                                        groupByWork(all).map(item => (
                                                            <List disablePadding>
                                                                <ListItemButton>
                                                                    <ListItemAvatar>
                                                                        <Avatar sx={
                                                                            {bgcolor: (GetCurrent(item.children).length / item.children.length * 100) === 100 ? theme.palette.success.light : setColor(item.name, theme)}}>
                                                                            {(GetCurrent(item.children).length / item.children.length * 100) === 100 ?
                                                                                <Done/> : setIcon(item.name, card)}
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText
                                                                        primary={`${item.name} ${getCurrentValue(GetCurrent(item.children).length)}/${getCurrentValue(item.children.length)}`}
                                                                        secondary={<LinearProgress color={'lesson'}  sx={card === 'IOS' ? {height: 7, borderRadius: '10px'} : {}} variant="determinate"
                                                                                                   value={GetCurrent(item.children).length / item.children.length * 100}/>}/>

                                                                </ListItemButton>
                                                            </List>
                                                        ))
                                                    }
                                                </Collapse>
                                                <Divider/>
                                            </React.Fragment> : null}
                                        {stats.map(item => (
                                            <StatElement disc={item} card={card}/>
                                        ))
                                        }
                                        {localStorage.getItem('top') === 'false' || !localStorage.getItem('top') ?
                                            <React.Fragment>
                                                <ListItemButton>
                                                    <ListItemText primary={
                                                        <b>{`Всего ${getCurrentValue(GetCurrent(all).length)}/${getCurrentValue(all.length)}`}</b>}
                                                                  secondary={<LinearProgress color={'lesson'}  sx={card === 'IOS' ? {height: 7, borderRadius: '10px'} : {}} variant="determinate"
                                                                                             value={GetCurrent(all).length / all.length * 100}/>}/>
                                                </ListItemButton>
                                                <Collapse in={true} timeout="auto" unmountOnExit>
                                                    {
                                                        groupByWork(all).map(item => (
                                                            <List disablePadding>
                                                                <ListItemButton>
                                                                    <ListItemAvatar>
                                                                        <Avatar sx={
                                                                            {bgcolor: (GetCurrent(item.children).length / item.children.length * 100) === 100 ? theme.palette.success.light : setColor(item.name, theme)}}>
                                                                            {(GetCurrent(item.children).length / item.children.length * 100) === 100 ?
                                                                                <Done/> : setIcon(item.name, card)}
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText
                                                                        primary={`${item.name} ${getCurrentValue(GetCurrent(item.children).length)}/${getCurrentValue(item.children.length)}`}
                                                                        secondary={<LinearProgress sx={card === 'IOS' ? {height: 7, borderRadius: '10px'} : {}} variant="determinate"
                                                                                                   value={GetCurrent(item.children).length / item.children.length * 100}/>}/>

                                                                </ListItemButton>
                                                            </List>
                                                        ))
                                                    }
                                                </Collapse>
                                                <Divider/>
                                            </React.Fragment> : null}
                                    </List>
                            }
                        </TabPanel>
                    </SwipeableViews>
                </Box>
                </MobileView>
                <BrowserView>
                    <Box sx={{flexGrow: 1, minHeight: '100%'}}>
                        <AppBar position="sticky">
                            <Box sx={{marginX: '30px'}}>
                                <StyledTabs
                                    hide
                                    variant={"fullWidth"}
                                    centered
                                    value={3}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    aria-label="full width tabs example"
                                >
                                    <StyledTab2Sel
                                        label={'БЛИЖАЙШЕЕ'}/>
                                    <StyledTab2Sel label="СВОДКА"/>
                                </StyledTabs>
                            </Box>
                        </AppBar>
                        <Grid direction={'row'} container>
                            <Grid sx={{maxHeight: '85vh', overflowY: 'scroll'}} item xs={6}>
                                {
                                    items.length === 0 ?
                                        <React.Fragment>
                                            <div style={{minHeight: '100%'}}>
                                                <div style={{
                                                    display: 'flex', justifyContent: 'center',
                                                    paddingTop: '25vh'
                                                }}>
                                                    <DoneAll sx={{fontSize: '15vh', color: theme.palette.primary.main}}/>
                                                </div>
                                                <div style={{
                                                    display: 'flex', justifyContent: 'center',
                                                    paddingTop: '5vh'
                                                }}>
                                                    <Typography variant={'h6'}><b>ПАРЫ ОТСУТСТВУЮТ</b></Typography>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {
                                                items[0].name !== getToday()
                                                    ? <div style={{minHeight: '10%'}}>
                                                        <div style={{
                                                            display: 'flex', justifyContent: 'center',
                                                            paddingTop: '20px'
                                                        }}>
                                                            <DoneAll sx={{fontSize: '7vh', color: theme.palette.primary.main}}/>
                                                        </div>
                                                        <div style={{
                                                            display: 'flex', justifyContent: 'center',
                                                            paddingTop: '10px'
                                                        }}>
                                                            <Typography variant={'body1'}><b>СЕГОДНЯ ПАР НЕТ</b></Typography>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            {items.map(block => (
                                                <React.Fragment>
                                                    <Typography sx={{ml: 3, mt: 3}} variant={'h6'} fontWeight={'bold'}>
                                                        {getToday() === block.name ? 'СЕГОДНЯ' : block.name}
                                                    </Typography>
                                                    {block.children.map(item =>
                                                        <Card variant={"outlined"} elevation={10} sx={{
                                                            backgroundColor: theme.palette.cardColor,
                                                            m: 1,
                                                            borderRadius: '10px',
                                                            minWidth: 200,
                                                            padding: 0,
                                                            border: '0px'
                                                        }}>
                                                            <Grid container alignItems="center">
                                                                <Grid sx={{textAlign: 'center'}} item xs={1}>
                                                                    <h2>{item.contentTableOfLessonsName}</h2>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <CardContent>
                                                                        <Stack direction="row"
                                                                               spacing={1}>
                                                                            <Chip sx={{mb: 1}} label={item.kindOfWork}
                                                                                  color={checkColor(item.kindOfWork)}/>
                                                                        </Stack>

                                                                        <Typography sx={{mb: 1}} variant="body1"
                                                                                    component="div">
                                                                            {item.discipline}
                                                                        </Typography>

                                                                        <Typography sx={{mb: 1}} color="text.secondary">
                                                                            {item.lecturer}
                                                                        </Typography>
                                                                        <Stack
                                                                            direction="row"
                                                                            divider={<Divider orientation="vertical"
                                                                                              flexItem/>}
                                                                            spacing={2}
                                                                        >
                                                                            <Typography fontWeight={"bold"}
                                                                                        variant="body2">{item.beginLesson}</Typography>
                                                                            <Typography fontWeight={'bold'}
                                                                                        variant="body2">{item.endLesson}</Typography>
                                                                        </Stack>
                                                                    </CardContent>
                                                                </Grid>
                                                            </Grid>
                                                        </Card>)}
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>}
                            </Grid>
                            <Grid sx={{maxHeight: '85vh', overflowY: 'scroll'}} item xs={6}>
                                {
                                    isLoading ? <div style={{
                                            display: 'flex', justifyContent: 'center',
                                            marginTop: '10vh'
                                        }}><CircularProgress/></div> :
                                        <List>
                                            {localStorage.getItem('top') === 'true' ?
                                                <React.Fragment>
                                                    <ListItemButton>
                                                        <ListItemText primary={
                                                            <b>{`Всего ${getCurrentValue(GetCurrent(all).length)}/${getCurrentValue(all.length)}`}</b>}
                                                                      secondary={<LinearProgress color={'lesson'}  sx={card === 'IOS' ? {height: 7, borderRadius: '10px'} : {}} variant="determinate"
                                                                                                 value={GetCurrent(all).length / all.length * 100}/>}/>
                                                    </ListItemButton>
                                                    <Collapse in={true} timeout="auto" unmountOnExit>
                                                        {
                                                            groupByWork(all).map(item => (
                                                                <List disablePadding>
                                                                    <ListItemButton>
                                                                        <ListItemAvatar>
                                                                            <Avatar sx={
                                                                                {bgcolor: (GetCurrent(item.children).length / item.children.length * 100) === 100 ? theme.palette.success.light : setColor(item.name, theme)}}>
                                                                                {(GetCurrent(item.children).length / item.children.length * 100) === 100 ?
                                                                                    <Done/> : setIcon(item.name, card)}
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={`${item.name} ${getCurrentValue(GetCurrent(item.children).length)}/${getCurrentValue(item.children.length)}`}
                                                                            secondary={<LinearProgress color={'lesson'}  sx={card === 'IOS' ? {height: 7, borderRadius: '10px'} : {}} variant="determinate"
                                                                                                       value={GetCurrent(item.children).length / item.children.length * 100}/>}/>

                                                                    </ListItemButton>
                                                                </List>
                                                            ))
                                                        }
                                                    </Collapse>
                                                    <Divider/>
                                                </React.Fragment> : null}
                                            {stats.map(item => (
                                                <StatElement disc={item}/>
                                            ))
                                            }
                                            {localStorage.getItem('top') === 'false' || !localStorage.getItem('top') ?
                                                <React.Fragment>
                                                    <ListItemButton>
                                                        <ListItemText primary={
                                                            <b>{`Всего ${getCurrentValue(GetCurrent(all).length)}/${getCurrentValue(all.length)}`}</b>}
                                                                      secondary={<LinearProgress color={'lesson'} sx={card === 'IOS' ? {height: 7, borderRadius: '10px'} : {}} variant="determinate"
                                                                                                 value={GetCurrent(all).length / all.length * 100}/>}/>
                                                    </ListItemButton>
                                                    <Collapse in={true} timeout="auto" unmountOnExit>
                                                        {
                                                            groupByWork(all).map(item => (
                                                                <List disablePadding>
                                                                    <ListItemButton>
                                                                        <ListItemAvatar>
                                                                            <Avatar sx={
                                                                                {bgcolor: (GetCurrent(item.children).length / item.children.length * 100) === 100 ? theme.palette.success.light : setColor(item.name, theme)}}>
                                                                                {(GetCurrent(item.children).length / item.children.length * 100) === 100 ?
                                                                                    <Done/> : setIcon(item.name, card)}
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={`${item.name} ${getCurrentValue(GetCurrent(item.children).length)}/${getCurrentValue(item.children.length)}`}
                                                                            secondary={<LinearProgress color={'lesson'} sx={card === 'IOS' ? {height: 7, borderRadius: '10px'} : {}} variant="determinate"
                                                                                                       value={GetCurrent(item.children).length / item.children.length * 100}/>}/>

                                                                    </ListItemButton>
                                                                </List>
                                                            ))
                                                        }
                                                    </Collapse>
                                                    <Divider/>
                                                </React.Fragment> : null}
                                        </List>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </BrowserView>
            </React.Fragment> : <FailPage/>
    )
}

function GetCurrent(items) {
    let date = new Date()
    return items.filter(value => {
        return value.date < date;
    })
}

function GetOther(items) {
    let date = new Date()
    return items.filter(value => {
        return value.date >= date;
    })
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function StatElement(props) {
    const theme = useTheme()
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <ListItemButton onClick={() => handleOpen()}>

                <ListItemText primary={<div><b>{props.disc.name}</b> <a
                    style={{opacity: 0.7}}>{ekz.includes(props.disc.name) ? '(экзамен)' : null}</a></div>}/>
            </ListItemButton>
            <Collapse in={true} timeout="auto" unmountOnExit>
                {
                    groupByWork(props.disc.children).map(item => {
                        const curr = GetCurrent(item.children).length / item.children.length * 100
                        return <List disablePadding>
                            <ListItemButton onClick={() => {

                            }
                            }>
                                <ListItemAvatar>
                                    <Avatar sx={
                                        {bgcolor: curr === 100 ? theme.palette.success.light : setColor(item.name, theme)}}>
                                        {curr === 100 ? <Done/> : setIcon(item.name, props.card)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${item.name} ${getCurrentValue(GetCurrent(item.children).length)}/${getCurrentValue(item.children.length)}`}
                                    secondary={<LinearProgress color={'lesson'}  sx={props.card === 'IOS' ? {height: 7, borderRadius: '10px'} : {}} variant="determinate"
                                                               value={curr}/>}/>

                            </ListItemButton>
                        </List>
                    })
                }
            </Collapse>
            <Divider/>
            <Dialog
                fullScreen
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
            >
                <AppBar position={"sticky"}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleClose()}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {props.disc.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={{backgroundColor: theme.palette.background.default}}>
                    {
                        GetCurrent(props.disc.children).map((item) => (
                            <Card variant={"outlined"} elevation={10} sx={
                                    {
                                        backgroundColor: 'transparent',
                                        m: 2,
                                        minWidth: 200,
                                        padding: 0,
                                        borderLeft: '10px',
                                        borderRight: '10px',
                                        borderTop: '10px',
                                    }
                                    }>
                                <Grid container alignItems="center">
                                    <Grid sx={{textAlign: 'center'}} item xs={2}>
                                        <Avatar sx={
                                            {bgcolor: theme.palette.success.light}}>
                                            {<Done/>}
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <CardContent>
                                            <Stack direction="row"
                                                   spacing={1}>
                                                <Chip variant={'outlined'} sx={
                                                        {mb: 2, border: '10px', fontWeight: 'bold', backgroundColor: theme.palette.cardColor}
                                                } label={item.kindOfWork.toUpperCase()}
                                                      color={checkColor(item.kindOfWork)}/>
                                            </Stack>
                                            <Box sx={{mb: 2}}>
                                                <Typography fontWeight={'bold'}>
                                                    {formatPreviewDate(item.date)}
                                                </Typography>
                                            </Box>
                                            <Stack
                                                direction="row"
                                                divider={<Divider orientation="vertical"
                                                                  flexItem/>}
                                                spacing={2}
                                            >
                                                <Typography fontWeight={"bold"}
                                                            variant="body2">{item.beginLesson}</Typography>
                                                <Typography fontWeight={'bold'}
                                                            variant="body2">{item.endLesson}</Typography>
                                            </Stack>
                                        </CardContent>
                                    </Grid>
                                </Grid>
                            </Card>
                        ))
                    }
                    {
                        GetOther(props.disc.children).map((item) => (
                            <Card variant={"outlined"} elevation={10} sx={
                                {
                                    backgroundColor: 'transparent',
                                    m: 2,
                                    minWidth: 200,
                                    padding: 0,
                                    borderLeft: '10px',
                                    borderRight: '10px',
                                    borderTop: '10px',
                                }
                            }>
                                <Grid container alignItems="center">
                                    <Grid sx={{textAlign: 'center'}} item xs={2}>
                                        <Avatar sx={
                                            {bgcolor: setColor(item.kindOfWork, theme)}}>
                                            {setIcon(item.name, 'IOS')}
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <CardContent>
                                            <Stack direction="row"
                                                   spacing={1}>
                                                <Chip variant={'outlined'} sx={
                                                    {mb: 2, border: '10px', fontWeight: 'bold', backgroundColor: theme.palette.cardColor}
                                                } label={item.kindOfWork.toUpperCase()}
                                                      color={checkColor(item.kindOfWork)}/>
                                            </Stack>
                                            <Box sx={{mb: 2}}>
                                                <Typography fontWeight={'bold'}>
                                                    {formatPreviewDate(item.date)}
                                                </Typography>
                                            </Box>
                                            <Stack
                                                direction="row"
                                                divider={<Divider orientation="vertical"
                                                                  flexItem/>}
                                                spacing={2}
                                            >
                                                <Typography fontWeight={"bold"}
                                                            variant="body2">{item.beginLesson}</Typography>
                                                <Typography fontWeight={'bold'}
                                                            variant="body2">{item.endLesson}</Typography>
                                            </Stack>
                                        </CardContent>
                                    </Grid>
                                </Grid>
                            </Card>
                        ))
                    }
                </Box>
            </Dialog>
        </React.Fragment>
    )
}