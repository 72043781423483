import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker';
import Box from "@mui/material/Box";
import {Alert, AlertTitle, Snackbar, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {StyledTab, StyledTabs, TabPanel} from "./Tab";
import {styled, useTheme} from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgba(45,42,42,0.23)',
        },
    },
    '& .MuiInputLabel-root': {
            color: 'rgba(45,42,42,0.23)',
    },
});

export function AddNotifyDialog() {
    const theme = useTheme()
    const [value, setValue] = React.useState(dayjs());
    const [open, setOpen] = React.useState(false);
    const [design, setDesign] = React.useState('success');

    const [tab, setTab] = React.useState(0);
    const [name, setName] = React.useState('Название заметки')
    const [content, setContent] = React.useState('Содержание заметки')

    const [snacked1, setSnacked1] = React.useState(false);
    const [snacked2, setSnacked2] = React.useState(false);

    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleContent = (event) => {
        setContent(event.target.value);
    };

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button sx={{borderWidth: 0}} color="inherit" onClick={handleClickOpen}>
                Добавить
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Alert sx={{m: 1}} severity={design}>
                        <AlertTitle>{name}</AlertTitle>
                        {content}
                    </Alert>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Добавить заметку
                        </Typography>
                        <Button autoFocus color="inherit" sx={{borderWidth: 0}} onClick={() => {setSnacked2(true)}}>
                            Сохранить
                        </Button>
                    </Toolbar>
                    <Toolbar>
                        <Box minWidth={'100%'}>
                            <StyledTabs value={tab} onChange={handleChange} centered>
                                <StyledTab label={'ДАТА'}/>
                                <StyledTab label={'СОДЕРЖАНИЕ'}/>
                                <StyledTab label={'ВИД'}/>
                            </StyledTabs>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Snackbar
                    onClose={() => setSnacked1(false)}
                    sx={{
                        marginBottom: 'env(safe-area-inset-bottom, 10px)',
                    }
                    }
                    open={snacked1}
                    autoHideDuration={1000}
                    message="Почему же не Рейнольдс?"
                />
                <Snackbar
                    onClose={() => setSnacked2(false)}
                    sx={{
                        marginBottom: 'env(safe-area-inset-bottom, 10px)',
                    }
                    }
                    open={snacked2}
                    autoHideDuration={2000}
                    message="Сашка, ну что ты такая вреднющая, ну не работает еще. Ей бы лишь потыкать :)"
                />
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={tab}
                    style={{backgroundColor: 'transparent'}}
                    onChangeIndex={setTab}
                >
                    <TabPanel index={tab} value={0}>
                        <LocalizationProvider adapterLocale={'ru'} dateAdapter={AdapterDayjs}>
                            <StaticDatePicker
                                label={"Выберите дату"}
                                displayStaticWrapperAs={'mobile'}
                                openTo="day"
                                value={value}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                componentsProps={{
                                    actionBar: {
                                        actions: [],
                                    },
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </TabPanel>
                    <TabPanel index={tab} value={1}>
                        <Box component="form"
                             noValidate
                             autoComplete="off" sx={{mx: 3, mt: 1}}>
                            <CssTextField
                                margin="normal"
                                fullWidth
                                id="outlined-multiline-flexible"
                                label="Название заметки"
                                multiline
                                value={name}
                                maxRows={2}
                                onChange={handleName}
                            />
                            <CssTextField
                                margin="normal"
                                fullWidth
                                id="outlined-multiline-flexible"
                                label="Содержание"
                                multiline
                                value={content}
                                onChange={handleContent}
                                rows={10}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel index={tab} value={2}>
                        <Box marginX={1} marginY={1}>
                            <ToggleButtonGroup
                                orientation={"vertical"}
                                color="primary"
                                size={"large"}
                                value={design}
                                exclusive
                                fullWidth={true}
                                onChange={(event, newDesign) => {
                                    if (newDesign !== null) {
                                        setDesign(newDesign)
                                    }
                                    if (newDesign === 'info') {
                                        setSnacked1(true)
                                    }
                                }}
                                aria-label="Platform"
                            >
                                <ToggleButton value="success">БЕЗОБИДНАЯ НАПОМИНАШКА</ToggleButton>
                                <ToggleButton value="info">РАЙАН ГОСЛИНГ</ToggleButton>
                                <ToggleButton value="warning">АЛАРМ!</ToggleButton>
                                <ToggleButton value="error">ВНИМЕНИЕ ПИЗДЕЦ</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </TabPanel>
                </SwipeableViews>


            </Dialog>
        </React.Fragment>
    );
}