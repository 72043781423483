import React, {useState} from 'react'
import {
    Box,
    Typography
} from "@mui/material";
import {Cancel, Settings} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";

export function FailPage(props) {
    const theme = useTheme()

    return (
        <Box sx={{flexGrow: 1, backgroundColor: theme.palette.background.paper, position: 'relative', top: 0, bottom: 0, left:0, right: 0, color: 'inherit'}}>
            <div style={{
                display: 'flex', justifyContent: 'center',
                marginTop: '30vh'
            }}>
                {props.type === 'fail'
                    ? <Cancel sx={{  fontSize: '20vh'}} />
                    : <Settings className={'App-logo'} sx={{  fontSize: '20vh'}} />}
            </div>
            <div style={{
                display: 'flex', justifyContent: 'center',
                marginTop: '10vh'
            }}>
                <Typography variant={'h5'}>{props.type === 'fail' ? 'ОШИБКА ПОДКЛЮЧЕНИЯ' : props.kind === '' ? 'ВЫБЕРИТЕ ГРУППУ' : 'ВОЙДИТЕ В БАРС'}</Typography>
            </div>
        </Box>
    )
}