import React, {useState} from 'react'
import {AppBar, Box, Paper, Stack, Toolbar, Typography} from "@mui/material";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import {StyledMapTab, StyledMapTabs} from "../Components/Tab";
import {useTheme} from "@mui/material/styles";
import {isBrowser} from "react-device-detect";

export function MapsPage() {
    const theme = useTheme()
    const [tab, setTab] = useState(0);
    const [map, setMap] = useState({val: {name: 'А', coor: [55.754791, 37.708138]}, index: 0});
    let [s, setS] = useState(null)

    const handleChange = (event, newValue) => {
        setTab(newValue);
        setMap({val: buildings[newValue], index: newValue})
    };

    const buildings = [
        {name: 'А', coor: [55.75591802780922, 37.707653305133945]},
        {name: 'Б', coor: [55.75526299383365, 37.70747072603729]},
        {name: 'В', coor: [55.755236, 37.708599]},
        {name: 'Г', coor: [55.754778965859956, 37.70895666983155]},
        {name: 'Д', coor: [55.75508226034756, 37.70999892792478]},
        {name: 'Е', coor: [55.756736, 37.703261]}, //OK
        {name: 'Ж', coor: [55.75442037793957, 37.70748753363757]}, //OK
        {name: 'З', coor: [55.754323570727195, 37.70663727337985]},
        {name: 'И', coor: [55.753860707908096, 37.70826000983385]},
        {name: 'К', coor: [55.75377419477667, 37.709304254713494]}, //OK
        {name: 'Л', coor: [55.75337334417504, 37.70967171734853]}, //OK
        {name: 'М', coor: [55.756072, 37.704157]}, //OK
        {name: 'Н', coor: [55.757045, 37.704361]}, //OK
        {name: 'Р', coor: [55.75365296726787, 37.70741090781484]},
        {name: 'С', coor: [55.75744610633679, 37.70238422882848]},
        {name: 'Т', coor: [55.75635279666448, 37.709043737978504]},
    ]

    const invertHex = (hex, isDark) =>
        isDark
            ? '#' + hex.match(/[a-f0-9]{2}/ig).map(e => (255 - parseInt(e, 16) | 0).toString(16).replace(/^([a-f0-9])$/, '0$1')).join('')
            : hex

    return (
        <Box sx={{flexGrow: 1, minHeight: '100vh'}}>
            {isBrowser ? null : <AppBar position="sticky">
                <Toolbar sx={{paddingY: 1}}>
                    <Typography fontWeight={'bold'} variant="h4" component="div">
                        КАРТА
                    </Typography>
                </Toolbar>
            </AppBar>}
            <Box>
                <Stack>
                    <YMaps>
                        <Map height={'100vh'} width={'100%'} defaultState={{center: map.val.coor, zoom: 17}}
                             state={{center: map.val.coor, zoom: 17}}
                             modules={[
                                 "templateLayoutFactory",
                                 'geoObject.addon.balloon',
                                 'geoObject.addon.hint'
                             ]}
                             onLoad={(ymaps) => {
                                 setS(ymaps)
                             }}
                        >
                            {buildings.map((value, index) => (
                                <Placemark geometry={value.coor} key={`value_${index}`}
                                           options={{
                                               iconLayout: s !== null ? s.templateLayoutFactory.createClass(
                                                   `<div style="display: flex; justify-content: center; align-items: center;border-radius: 30px; min-width: 30px; min-height: 30px; background-color: ${theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.background.default};border-width: ${index === map.index ? '4px' : '2px'}; border-style: solid; border-color: ${index === map.index ? invertHex(theme.palette.lesson.main, theme.palette.mode === 'dark') : invertHex('black', theme.palette.mode === 'dark')}"><b>${value.name}</b></div>`
                                               ) : null,
                                               //preset: index === map.index ? 'circleDotIcon' : 'islands#circleIcon',
                                               //iconColor: index === map.index ? invertHex(theme.palette.lesson.main, theme.palette.mode === 'dark') : invertHex(theme.palette.secondary.main, theme.palette.mode === 'dark')
                                           }}
                                />
                            ))}
                        </Map>
                    </YMaps>
                    <Paper sx={
                        {
                            height: '10vh',
                            position: 'fixed',
                            bottom: isBrowser ? 10 : 40,
                            pb: 'env(safe-area-inset-bottom, 10px)',
                            left: 0,
                            right: 0,
                            backgroundColor: 'transparent'
                        }
                    } elevation={0}>
                        <StyledMapTabs
                            //orientation={isBrowser ? 'vertical' : 'horizontal'}
                            value={tab}
                            onChange={handleChange}
                            indicatorColor="primary"
                            // textColor="inherit"
                            aria-label="full width tabs example"
                        >
                            {buildings.map((value, index) => (
                                <StyledMapTab
                                    label={<Typography color={tab === index ? theme.palette.lesson.main : 'inherit'}
                                                       fontWeight={'bold'}
                                                       variant={'subtitle1'}>{`КОРПУС ${value.name}`}</Typography>}/>
                            ))
                            }

                        </StyledMapTabs>
                    </Paper>
                </Stack>
            </Box>
        </Box>
    )
}