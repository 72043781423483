import React, {useState} from 'react'
import {
    AppBar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar,
    Typography
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {PaletteContext} from "../theme";
import {StyledTab, StyledTab2, StyledTabs, TabPanel} from "../Components/Tab";
import {
    Android,
    Apple,
    AutoStories, BrightnessAuto, DarkMode,
    IntegrationInstructions,
    LightMode,
    LightModeOutlined,
    Settings,
    Update, ViewCarousel, ViewDay, ViewDayOutlined, Tab
} from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import {VersionView} from "../version";
import Encrypt from "../crypt";
import {isBrowser} from "react-device-detect";
import {ApiPage} from "./api";

export function SettingsPage() {
    const theme = useTheme()
    const [group, setGroup] = useState('ИЭозс-62-20')
    const [user, setUser] = useState(localStorage.getItem("bars-user") === null ? 'bars-user' : localStorage.getItem("bars-user"));
    const [pass, setPass] = useState('password');

    const [checked, setChecked] = useState(localStorage.getItem("days") === 'true')
    const [checked2, setChecked2] = useState(localStorage.getItem("top") === 'true')
    const [checked3, setChecked3] = useState(localStorage.getItem("rrr") === 'true')

    const [alignment, setAlignment] = React.useState(localStorage.getItem('theme2') ? JSON.parse(localStorage.getItem('theme2')).color : null);
    const [design, setDesign] = React.useState(localStorage.getItem('theme2') ? JSON.parse(localStorage.getItem('theme2')).design : null);
    const [platform, setPlatform] = React.useState(localStorage.getItem('desPlat') ? localStorage.getItem('desPlat') : 'IOS')
    const [card, setCard] = React.useState(localStorage.getItem('desCard') ? localStorage.getItem('desCard') : 'IOS')
    const [dTab, setDTab] = React.useState(localStorage.getItem('desTab') ? localStorage.getItem('desTab') : 'column')

    const [tab, setTab] = useState(0);
    const [snow, setSnow] = useState(localStorage.getItem("snow") === "true");
    const [uiVer, setVer] = useState(localStorage.getItem("uiVersion") === "true");

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <PaletteContext.Consumer>
            {context =>
                <Box sx={{flexGrow: 1, minHeight: '100vh'}}>
                    <AppBar position="sticky">
                        { isBrowser ? null : <Toolbar>
                            <Typography fontWeight={"bold"} variant="body2" component="div" sx={{flexGrow: 1, textAlign: 'center', opacity: '0.5'}}>
                                НАСТРОЙКИ
                            </Typography>
                        </Toolbar> }
                        <Box>

                                <StyledTabs
                                    hide
                                    value={tab}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    aria-label="full width tabs example"
                                >
                                    <StyledTab2  label={"НАСТРОЙКА"}/>
                                    <StyledTab2  label={"БАРС"}/>
                                    <StyledTab2  label="ОБНОВЛЕНИЯ"/>
                                    <StyledTab2  label="ТЕСТИРОВАНИЕ API"/>
                                </StyledTabs>

                        </Box>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={tab}
                        onChangeIndex={setTab}>
                        <TabPanel index={0} value={tab}>
                        <Box
                            component="form"
                            sx={{
                                mb: 5,
                                '& .MuiTextField-root': {m: 1, width: '25ch'},
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <Typography sx={{mb: 2, mt: 2, mx: 2}} variant="h6" color="text.secondary">Первоначальная
                                    настройка</Typography>
                                <Divider sx={{mb: 2}}/>
                                <Stack direction="row" spacing={1}>
                                    <TextField
                                        fullWidth={true}
                                        required
                                        id="outlined-required"
                                        label="Группа"
                                        defaultValue="ИЭозс-62-20"
                                        onChange={(event) => {
                                            setGroup(event.target.value)
                                        }}
                                    />
                                    <Button onClick={() => {
                                        localStorage.setItem('group', group)
                                        fetch(`api/search?term=${group}&type=group`, {})
                                            .then(res => res.json())
                                            .then(
                                                (result) => {
                                                    if (result.length === 0) {
                                                        alert('нет такой группы')
                                                    } else {
                                                        localStorage.setItem('groupId', result[0].id)
                                                    }
                                                },
                                                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                                                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                                                (error) => {
                                                    alert(error)
                                                }
                                            )
                                    }}>Применить</Button>
                                </Stack>
                                <Stack marginLeft={1}>
                                    <FormControlLabel control={<Checkbox onChange={event => {
                                        setChecked(!checked)
                                        localStorage.setItem('days', !checked)
                                    }} sx={{'& .MuiSvgIcon-root': {fontSize: 28}}} checked={checked}/>}
                                                      label="Считать дни в сводке"/>
                                </Stack>
                                <Stack marginLeft={1}>
                                    <FormControlLabel control={<Checkbox onChange={event => {
                                        setChecked2(!checked2)
                                        localStorage.setItem('top', !checked2)
                                    }} sx={{'& .MuiSvgIcon-root': {fontSize: 28}}} checked={checked2}/>}
                                                      label="Итоги сверху"/>
                                </Stack>
                                <Stack marginLeft={1}>
                                    <FormControlLabel control={<Checkbox onChange={event => {
                                        setChecked3(!checked3)
                                        localStorage.setItem('rrr', !checked3)
                                    }} sx={{'& .MuiSvgIcon-root': {fontSize: 28}}} checked={checked3}/>}
                                label="Режим фанатки Райана Рейнольдса :)"/>
                                </Stack>
                                <Stack marginLeft={1}>
                                    <FormControlLabel control={<Checkbox onChange={event => {
                                        setSnow(!snow)
                                        localStorage.setItem('snow', !snow)
                                    }} sx={{'& .MuiSvgIcon-root': {fontSize: 28}}} checked={snow}/>}
                                                      label="Снегопад (требуется перезапуск)"/>
                                </Stack>
                                <Stack marginLeft={1}>
                                    <FormControlLabel control={<Checkbox onChange={event => {
                                        setVer(!uiVer)
                                        localStorage.setItem('uiVersion', !uiVer)
                                    }} sx={{'& .MuiSvgIcon-root': {fontSize: 28}}} checked={uiVer}/>}
                                                      label="Старое расписание"/>
                                </Stack>
                                <Typography sx={{mb: 2, mt: 2, mx: 2}} variant="h6"
                                            color="text.secondary">Оформление</Typography>
                                <Divider sx={{mb: 2}}/>
                                <Box marginX={1}>
                                    <ToggleButtonGroup
                                        orientation={"horizontal"}
                                        color="primary"
                                        size={"large"}
                                        value={design}
                                        exclusive
                                        fullWidth={true}
                                        onChange={(event, newDesign) => {
                                            if (newDesign !== null) {
                                                setDesign(newDesign);
                                                context.usePalette({color: alignment, design: newDesign})
                                                localStorage.setItem('theme2', JSON.stringify({
                                                    color: alignment,
                                                    design: newDesign
                                                }))
                                            }
                                        }}
                                        aria-label="Platform"
                                    >
                                        <ToggleButton value="extended"><LightMode color={'warning'}/></ToggleButton>
                                        <ToggleButton value="dark"><DarkMode color={'info'}/></ToggleButton>
                                        <ToggleButton value="auto"><BrightnessAuto/></ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </div>
                        </Box>
                        </TabPanel>
                        <TabPanel index={1} value={tab}>
                            <Box
                                component="form"
                                sx={{
                                    mx: 5,
                                    mb: 5,
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <Typography sx={{mb: 2, mt: 2, mx: 2}} variant="h6" color="text.secondary">Учётная запись БАРС</Typography>
                                    <Divider sx={{mb: 2}}/>
                                    <Stack direction="column" spacing={3}>
                                        <TextField
                                            fullWidth
                                            required
                                            id="outlined"
                                            label="Логин"
                                            defaultValue={user}
                                            onChange={(event) => {
                                                setUser(event.target.value)
                                            }}
                                        />
                                        <TextField
                                            type={'password'}
                                            fullWidth={true}
                                            required
                                            id="outlined-required1"
                                            label="Пароль"
                                            defaultValue={''}
                                            onChange={(event) => {
                                                setPass(event.target.value)
                                            }}
                                        />
                                        <Button variant={'contained'} onClick={() => {
                                            localStorage.setItem('bars-user', user)
                                            localStorage.setItem('token', String(Encrypt(JSON.stringify({login: user, password: pass}))))
                                        }}>
                                            <Typography variant={'h6'}>
                                                Сохранить
                                            </Typography>
                                        </Button>
                                    </Stack>
                                </div>
                            </Box>
                        </TabPanel>
                        <TabPanel index={2} value={tab}>
                            <VersionView/>
                        </TabPanel>
                        <TabPanel index={3} value={tab}>
                            <ApiPage/>
                        </TabPanel>
                    </SwipeableViews>
                </Box>
            }
        </PaletteContext.Consumer>
    )
}
